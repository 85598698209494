import React, {useState, useCallback, useEffect} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import DeviceApi from "../../../services/ApiServices/DeviceApi";
import SVGraphics from "../../../assets/SVGraphics";
import AddDeviceModal from "../../modals/AddDeviceModal";
import AssignDeviceModal from "../../modals/AssignDeviceModal";
import SuperAdminApi from "../../../services/ApiServices/SuperAdminApi";
import {makeWarning} from "../../modals/WarningModal";
import AddNewSoftwareModal from "../../modals/AddNewSoftwareModal";
import moment from "moment";


export function SoftwareVersions() {
    const [showModal, setShowModal] = useState(false);
    const [isAssignDevice, setIsAssignDevice] = useState(false);
    const [swVersions, setSwVersions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);


    useEffect( () => {
        fetchData().then()

    }, [])
    const fetchData= async()=> {
        let versions =  await DeviceApi.getAllSoftwareVersions()
        if(versions && versions.data){
            setSwVersions(versions.data)
            setFilteredData(versions.data)
            setIsLoading(false)
        }
    }
    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);

    }, []);

    const saveVersions = useCallback(() => {
        setShowModal(false);
        fetchData().then()
    }, []);

    // const handlePopoverOpened = (id) => {
    //     if (slideMoveRef.current !== null) {
    //         clearTimeout(slideMoveRef.current);
    //     }
    // }

    const columns = [
        {
            Header: 'Version',
            accessor: 'SWVersion' // accessor is the "key" in the data
        },
        {
            Header: 'Upload Date',
            accessor: 'uploadDate',
            Cell: ({ cell }) => {
                return <div>{moment(cell.row.original.uploadDate).format('DD/MM/yyyy')}</div>
            }
        },
        {
            Header: 'Files',
            accessor: 'filesPath',
            Cell: ({ cell }) => {
                return <div className={'flex '}> <SVGraphics className="text-gray-600 h-4 w-4 fill-current cursor-pointer mr-2 items-center" svgname={'files'}  />{`${cell.row.original.SWVersion}-upgradeFile.enc`}</div>
            }
        },
        {
            Header: '',
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            closeModal={closeModal}
                            class={"h-25"}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly p-2">
                                <div className={'flex justify-between h-1/2 cursor-pointer p-1'} onClick={()=>removeVersion(cell.row.original)}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    const removeVersion = async(data) =>{
        let alert = await makeWarning({proceedLabel:'Yes',cancel:'Cancel', title:`Delete SW version ${data.SWVersion}`,message:`Are You Sure You want to remove version ${data.SWVersion}?`})
        if(alert){
            let swToRemove = {SWVersion:data.SWVersion}
            let removeSW = await DeviceApi.deleteSW(swToRemove)
            fetchData().then()
        }
    }

    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < swVersions.length; i++) {
            filteredData = swVersions.filter(device =>
                 // String(device['BLEMACAddress']).toLowerCase().indexOf(filterValue) > -1 ||
                String(device['SWVersion']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Software Versions"} className={"font-medium text-titleColor text-xl"}/>
                 <ButtonSanolla onClick={handleShowModal} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'+ Add new SW'}/>
            </span>


            <div className={"w-full h-[680px]"}>
                    <Table data={filteredData?filteredData:[]} isLoading={isLoading} columns={columns} headerClassName={"text-[0.875rem]"}/>
            </div>
            {showModal &&
                <AddNewSoftwareModal
                    onCancel={handleCloseModal}
                    saveVersions={()=>saveVersions()}
                />
            }
        </div>
    )
}
