import {authenticationService} from "../AuthinticationService";
import Config from "../../config/Config";
import restApi from "./RestApi";


const globalUrl = Config.globalUrl

const CliniciansApi = {
    createPatient: async (body) => {
        try {
            // let currentUser = authenticationService.currentUserValue
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/createPatient`,
                body,
                requestconfig

            );
        } catch (err) {
            return err
        }
    },

    editPatient: async (body,id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/editPatient`,
                body,
                requestconfig

            );
        } catch (err) {
            return err
        }
    },

    getAllPatients: async () => {
        try {

            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.get(
                 `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/getAllPatients`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getAllPatientNotes: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.get(
                 `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/getAllPatientNotes`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

    getPatientExams: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.get(
                 `${globalUrl}api/v1/webapp/exams/${clinician.profile.UserStorageId}/${id}/getExam`,
                requestconfig

            );
        } catch (err) {
            return err
        }
    },
    searchPatient: async (search) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            let body={key:search}
            return await restApi.post(
                 `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/searchPatient`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getPatient: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.get(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/getPatientByID`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deletePatient: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.delete(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/deletePatient`,
                requestconfig
            );
        } catch (err) {
            return err
        }


    },
    editPatientNote: async (body,id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/${body.Index}/updatePatientNote`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    addPatientNote: async (body,id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let clinician = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': clinician.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/patients/${clinician.profile.UserStorageId}/${id}/addPatientNote`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

};

export default CliniciansApi
