import React, {useState, useCallback, useEffect, useRef} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import DeviceApi from "../../../services/ApiServices/DeviceApi";
import SVGraphics from "../../../assets/SVGraphics";
import AddDeviceModal from "../../modals/AddDeviceModal";
import AssignDeviceModal from "../../modals/AssignDeviceModal";
import SuperAdminApi from "../../../services/ApiServices/SuperAdminApi";
import {makeWarning} from "../../modals/WarningModal";
import UpdateSoftwareModal from "../../modals/UpdateSoftwareModal";
import {authenticationService} from "../../../services/AuthinticationService";




export function Devices() {
    const [showModal, setShowModal] = useState(false);
    const [showUpdateSW, setShowUpdateSW] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isAssignDevice, setIsAssignDevice] = useState(false);
    const [devices, setDevices] = useState([]);
    const [device, setDevice] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [updateType, setUpdateType] = useState('');
    const [editData, setEditData] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = React.useState([]);
    const [userRole, setUserRole] = useState(null);
    const [clearCheckBox, setClearCheckBox] = useState(false);


    useEffect( () => {
        fetchData().then()
        authenticationService.currentUser.subscribe(
            currentUser => setUserRole(currentUser['role'][0])
        );

    }, [])
    const fetchData= async()=> {
        let _devices = await DeviceApi.getAllDevices()
        if(_devices && _devices.data){
            setDevices(_devices.data)
            setFilteredData(_devices.data)
            setIsLoading(false)
        }
    }
    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        setIsEdit(false)
        setDevice({})
    }, []);
    const handleOpenUpdateSW = useCallback((device) => {
        if(device && device['BLEMACAddress']){
            setSelectedRowIds([device])
        }
        setShowUpdateSW(true);
        setCloseModal(false)
    }, [showModal]);

    const handleCloseUpdateSW = useCallback(() => {
        setSelectedRowIds([])
        setClearCheckBox(!clearCheckBox)
        setShowUpdateSW(false);
        fetchData().then()
    }, [showModal]);


    const columns = [
        {
            Header: 'Type',
            accessor: 'Type', // accessor is the "key" in the data
        },
        {
            Header: 'Device Version',
            accessor: 'SWVersion',
        },
        {
            Header: 'Version Status',
            accessor: 'DeviceVersionStatus',

        },
        {
            Header: 'License Code',
            accessor: 'LicenseValidityCode',
        },
        {
            Header: 'S/N',
            accessor: 'SN',
        },
        {
            Header: 'BLE MAC',
            accessor: 'BLEMACAddress',
        },
        {
            Header: 'Organization',
            accessor: 'OrganizationName',
            isVisible:userRole==='SanollaAdmin'
        },
        {
            Header: 'Warranty End',
            accessor: 'WarrantyExpiration',
        },
        {
            Header: '',
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            closeModal={closeModal}
                            class={"h-48"}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly p-2">
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer p-1'} onClick={()=>openAssignDevice(cell.row)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Assign</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer p-1'} onClick={()=>openEditDevice(cell.row.original)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer p-1'} onClick={()=>handleOpenUpdateSW(cell.row.original)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Update SW</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`update`}/></div>
                                <div className={'flex justify-between h-1/2 cursor-pointer p-1'} onClick={()=>removeDevice(cell.row.original)}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    const removeDevice = async(data) =>{
        setCloseModal(true)
        let remove
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this Device?",message:"This Device will be hidden immediately, you can’t undo this action."});
        if(message) {
            remove = await DeviceApi.deleteDevice(data.BLEMACAddress)
            if(remove){
                fetchData().then(p=>{
                    setDevices(p.data)
                    setFilteredData(p.data)
                    setIsLoading(false)
                })
            }
        }
        setCloseModal(false)
    }
    const openEditDevice = (data) => {
        setIsEdit(true)
        setDevice(data)
        setShowModal(true)
        setCloseModal(false)
    }
    const openAssignDevice = async (data) => {
        setUpdateType('assign')
        setCloseModal(false)
        let org = await SuperAdminApi.getAllOrganizations()
        if(org && org.data){
            let _organizations = []
            org.data.forEach(item=>{
                _organizations.push({
                    label:item.Name,
                    value:item.OrganizationID
                })
            })
            setEditData(_organizations)
        }
        setDevice(data.original)
        setIsAssignDevice(true)
    }

    const handleCreateDevice = useCallback(() => {
        fetchData().then(p=>{
            setDevices(p.data)
            setFilteredData(p.data)
            setIsAssignDevice(false)
        })
        handleCloseModal()
    }, [handleCloseModal]);
    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < devices.length; i++) {
            filteredData = devices.filter(device =>
                String(device['BLEMACAddress']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['DeviceVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SN']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['Type']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['OrganizationName']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SWVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            )
        }
        setFilteredData(filteredData)
    }

    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />

            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Devices List"} className={"font-medium text-titleColor text-xl"}/>
                <div>
                 <ButtonSanolla onClick={handleOpenUpdateSW} disabled={selectedRowIds.length===0} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor mr-4 disabled:bg-gray-300 disabled:border-gray-300'} label={'+ Update Software'}/>
                 <ButtonSanolla onClick={handleShowModal} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'+ Add Device'}/>
                </div>
            </span>
            {showModal &&
            <AddDeviceModal onCancel={handleCloseModal}
                            onSubmit={handleCreateDevice}
                            entry={device}
                            isEdit={isEdit}
            />}
            {isAssignDevice &&
                <AssignDeviceModal
                data={editData}
                onSubmit={handleCreateDevice}
                onCancel={()=>setIsAssignDevice(false)}
                type={updateType}
                entry={device}
                />
            }
        {showUpdateSW && selectedRowIds.length>0 &&
                <UpdateSoftwareModal
                data={editData}
                onSubmit={handleCloseUpdateSW}
                onCancel={handleCloseUpdateSW}
                selectedRowIds={selectedRowIds}
                type={updateType}
                entry={device}
                />
            }
            <div className={"w-full h-[680px]"}>
                    <Table
                        data={filteredData?filteredData:[]}
                        isLoading={isLoading}
                        columns={columns}
                        _selectedRowIds={selectedRowIds}
                        headerClassName={"text-[0.875rem]"}
                        onRowSelectStateChange={setSelectedRowIds}
                        clearCheckBox={clearCheckBox}
                    />
            </div>
        </div>
    )
}
