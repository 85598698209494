import React from "react";
import {TextSanolla} from "./TextSanolla";

export function TextAreaSanolla(props) {

    return (
        <div className={props.containerClassName}>

            <textarea disabled={props.disabled} name={props.name} maxLength={props.maxLength} className={props.textAreaInputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>
            <TextSanolla text={props.label} className={props.labelClassName} style={props.textAreaStyle}/>
        </div>
    )
}
