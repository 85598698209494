import React, {useCallback, useEffect, useState} from "react";
import {ActionsMorePopover} from "../../../table/tableMore/ActionsMorePopover";
import SVGraphics from "../../../../assets/SVGraphics";
import AddEditNoteModal from "../../../modals/AddEditNoteModal";


export function PatientsNotes(props) {

    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [note, setNote] = useState(null);
    const [notes, setNotes] = useState([]);
    const [closeModal, setCloseModal] = useState(false);

    useEffect( () => {
        setNotes(props.notes)
    }, [])
    useEffect( () => {
        if(props.isAddNote){
            setIsEdit(false)
            setShowModal(true)
        }else{
            setShowModal(false)
        }
    }, [props.isAddNote])

    const editNote = (n) => {
        setNote(n)
        setCloseModal(true)
        setIsEdit(true)
        setShowModal(true)
    }
    const handleCloseModal = useCallback(() => {
        setCloseModal(false)
        setIsEdit(false)
        setShowModal(false);
        setCloseModal(false)
        props.closeModal()

    }, []);

    const saveNote = (newNote) => {
        let updateNotes = [...notes]
        if(isEdit){
            setCloseModal(false)

            let index = notes.findIndex(x=>x.date===newNote.creationDate)
            if(index>-1){
                let noteIndex = updateNotes[index].notes.findIndex(x=>x.Index===note.Index)
                if(noteIndex>-1){
                    updateNotes[index].notes[noteIndex]=newNote

                }
            }
        }else{
            let index = notes.findIndex(x=>x.date===newNote.creationDate)

            if(index>-1){
                let _notes = notes[index].notes
                _notes.push(newNote)
                updateNotes[index].notes=_notes
            }else{
                updateNotes.push({
                    date:newNote.creationDate,
                    note:newNote
                })
            }

            props.closeModal()
        }
        setNotes(updateNotes)
        handleCloseModal()

    }
    console.log("props.isAddNote",props.isAddNote)
    return (
        <div
            className="w-full font-Poppins mb-32">
            {
                props.notes.map(note => {
                    return <div className={"w-full"} key={note.date}>
                        <div className={"mt-6 text-[#657690] text-[0.875rem] font-Poppins ml-4"}>{note.date}</div>
                        <div
                            className={"inline-grid gap-4 grid-cols-3 w-full"}>
                            {note.notes.map(n => {
                                return <div
                                    key={n.Index}
                                    className={"w-full h-28 bg-white border border-[#EBF2FC] px-4 rounded-xl mt-3 relative"}>
                                    <div className={'flex justify-between pt-3'}>
                                        <div className={"text-titleColor font-medium text-[0.875rem] font-Poppins px-3"}>
                                            {n.NoteTitle}
                                        </div>
                                        <div className={"flex justify-end"}>
                                            <ActionsMorePopover
                                                closeModal={closeModal}
                                                class={'h-16'}
                                                cell={<div
                                                    className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                                    <div className={'flex justify-between h-1/2 cursor-pointer'}
                                                         onClick={() => editNote(n)}>
                                                        <div
                                                            className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit
                                                        </div>
                                                        <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"}
                                                                    svgname={`edit`}/></div>
                                                </div>}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={"text-[#415576] font-normal text-[0.875rem] font-Poppins px-3"}> {n.Note}</div>
                                    <div
                                        className={"text-titleColor font-medium text-xs font-Poppins absolute bottom-3 left-7"}>{`${n.creationDate} ${n.creationTime}`}</div>
                                </div>
                            })}
                        </div>
                    </div>
                })
            }
            {showModal &&
            <AddEditNoteModal
                onCancel={handleCloseModal}
                onSubmit={saveNote}
                entry={note}
                isEdit={isEdit}
                userId={props.userId}
            />
            }

        </div>
    )
}
