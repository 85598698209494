import React from "react";
import { useTable, useSortBy,useRowSelect,usePagination } from 'react-table'
import down from "../../assets/arrows/down.svg"
import TablePagination from "./Pagination";
import {NoData} from "./NoData";
import {LoadingComponent} from "../input/LoadingComponent";



export function Table(props) {

    let {data,columns,onClick, isLoading,onRowSelectStateChange,clearCheckBox} = props

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        selectedFlatRows,
        state: { pageIndex,selectedRowIds },
        toggleAllRowsSelected,
    } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 9 } },
        useSortBy,
        usePagination,
        useRowSelect,

        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest}, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    {/*<input type="checkbox" style={{border:'1.5px solid #3072E3'}} ref={resolvedRef} class={"rounded-4px w-[16px] h-[16px]"}  {...rest} />*/}
                    <div className={`checkbox-wrapper`}>
                        <input type={"checkbox"} ref={resolvedRef} {...rest}/>
                    </div>
                    {/*<InputCheckboxSanolla*/}
                    {/*    ref={resolvedRef}*/}
                    {/*/>*/}
                </>
            )
        }
    )
    // console.log("selectedRowIds",selectedRowIds,
    //     selectedFlatRows.map(
    //         d => d.original))
    // const sortItems = (prev, curr, columnId) => {
    //     if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
    //         return 1;
    //     } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
    //         return -1;
    //     } else {
    //         return 0;
    //     }
    // };
    React.useEffect(() => onRowSelectStateChange?.(

        selectedFlatRows.map(
            d => d.original)),
        [
        onRowSelectStateChange,
        selectedRowIds,
    ]);
    React.useEffect(() => (
            toggleAllRowsSelected()
    ),
        [clearCheckBox]);

    return (
        <>
            {isLoading &&
            <LoadingComponent/>
            }
            {props.data.length > 0 ?
                <>
                    <table {...getTableProps()} id="myTable" className={"w-full text-left text-gray-500 mt-5"}
                           style={{borderCollapse: 'collapse  !important'}}>
                        <thead className="text-base text-headersColor bg-headersBackground sticky"
                               style={{borderRadius: '50px!important', borderCollapse: 'collapse  !important'}}>
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`py-3 px-6 ${props.headerClassName}`}
                                        // onClick={(prev, curr, columnId) => {
                                        //     return sortItems(prev, curr, columnId);
                                        // }}
                                    >
                                        {column.render('Header')}
                                        <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? <img className={'inline-block ml-[7px]'} alt={'down'} src={down}/>
                            : <img className={'rotate-180 inline-block ml-[7px]'} alt={'up'} src={down}/>
                        : ''}
                  </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} >
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr key={row.id} {...row.getRowProps()}
                                    className="bg-white border border-headersBorderColor max-h-6 h-6  "
                                >
                                    {row.cells.map(cell => {

                                        return (
                                        // <OverlayTrigger
                                        //     key={'top'}
                                        //     placement={'top'}
                                        //     overlay={
                                        //         <Tooltip id={`tooltip-${'top'}`}
                                        //                  style={{
                                        //                      position: 'absolute',
                                        //                      backgroundColor: 'rgba(87,84,84,0.85)',
                                        //                      padding: '2px 10px',
                                        //                      color: 'white',
                                        //                      borderRadius: 3,
                                        //                      ...props.style,
                                        //                  }}
                                        //         >
                                        //             {cell.render('Cell')}
                                        //         </Tooltip>
                                        //     }
                                        // >
                                        //     <td key={cell.id} className="py-4 pl-6 pr-1 overflow-hidden text-ellipsis max-w-[160px] whitespace-nowrap"
                                        //         onClick={cell.column.id !== '[editButton]' ? () => onClick(row) : null}
                                        //     >
                                        //         {cell.render('Cell')}
                                        //     </td>
                                        // </OverlayTrigger>

                                            <td key={cell.column.id} className="py-4 pl-6 pr-1 overflow-hidden text-ellipsis max-w-[11.25rem] whitespace-nowrap"
                                                onClick={cell.column.id!=='selection' &&  cell.column.id !== '[editButton]' ? () => onClick(row) : null}
                                            >
                                                {cell.render('Cell')?cell.render('Cell'):''}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <div className={"w-full"}>
                        <TablePagination
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            previousPage={previousPage}
                            canPreviousPage={canPreviousPage}
                            nextPage={nextPage}
                            canNextPage={canNextPage}
                        />
                    </div>
                </> :
                <NoData/>
            }
            </>
    // {/*<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>*/}
    // {/*<pre>*/}
    // {/*    <code>*/}
    // {/*      {JSON.stringify(*/}
    // {/*          {*/}
    // {/*              selectedRowIds: selectedRowIds,*/}
    // {/*              'selectedFlatRows[].original': selectedFlatRows.map(*/}
    // {/*                  d => d.original*/}
    // {/*              ),*/}
    // {/*          },*/}
    // {/*          null,*/}
    // {/*          2*/}
    // {/*      )}*/}
    // {/*    </code>*/}
    // {/*  </pre>*/}

    )
}
