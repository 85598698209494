import React, {useEffect, useState} from "react";
import formData from "../../forms/formsData.json";
import {SanollaForm} from "../../forms/SanollaForm";
import {useNavigate} from "react-router-dom";
import utils from "../../../utils/utils";
import {Error} from "../../forms/Error";
import {TextSanolla} from "../../input/TextSanolla";
import SVGraphics from "../../../assets/SVGraphics";
import {LoginAPICalls} from "../../../services/ApiService";
import Constants from "../../../utils/constants"
import {object, string} from "yup";

export function ForgotPassword() {
    const [LoginInputs, setLoginInputs] = useState({
        Email: "",
    });
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [formType, setFormType] = useState(formData['forgotPassword'])
    const [BackendErrors, setBackendErrors] = useState(null);
    const [disabled, setDisabled] = useState(true)
    const [count, setCount] = useState(true)

    const forgotPassSchema = object({
        Email: string().email("Please enter valid email").required().typeError("Email is Required")
    })
    useEffect(() => {
        setCount(!count)
    },[LoginInputs,error])

    const SubmitFunc = async () => {
        let submit = await LoginAPICalls.forgetPass(LoginInputs)
        if(submit){
            navigate('/login')
        }
    }
    
    const handleChange = async (event) => {
        let inputs = {...LoginInputs}
        let name = event.target.name
        let value = event.target.value
        LoginInputs[name]=event.target.value
        let form = [...formType]
        let formErrors = {...error}
        // switch (name) {
        //
        //     case 'Email':
        //         if(value && !utils.mailValidation(value)){
        //             setError({Email:true})
        //             setIsError(true)
        //             formType[0]['error'] = "Please enter valid email address"
        //         }else if(!value){
        //             formType[0]['error'] = "Please enter email address"
        //             setError({Email:true})
        //             setIsError(true)
        //         }
        //         else{
        //             setError({Email:false})
        //             setIsError(false)
        //             formType[0]['error'] = ""
        //         }
        //         break;
        //     default: setIsError(false)
        // }
        forgotPassSchema.validate(inputs).then(function() {
            formErrors[name] = false
            setDisabled(false)
        })
            .catch(function(err) {
                if(err){
                    setIsError(true)
                    let index = form.findIndex(x=>x.name===name)
                    form[index]['error'] = err.errors
                    if(err.path===name){
                        formErrors[name] = true
                        setDisabled(true)
                    }else{
                        formErrors[name] = false
                    }
                }
                else{
                    formErrors[name] = false
                    setDisabled(false)
                }
            });
        setError(formErrors)
        inputs[name] = value
        setFormType(form)
        setLoginInputs(inputs)
        setBackendErrors(null)
    }


    return (
        <div className={"w-[28rem] h-[25.25rem] m-auto bg-white shadow-2xl rounded-3xl flex items-center justify-center relative top-44"}>
            <div className={"flex flex-col justify-center w-4/6"}>
                <TextSanolla text={"Forgot Password?"} className={"text-titleColor text-3xl font-Rubik font-semibold text-center w-full " }/>
                <div className={"text-titleColor text-l font-Poppins mt-2 text-center"}>No worries, we will send you reset instructions by mail</div>


                <div className={"mt-12"}>
                    <SanollaForm
                        data={formType}
                        formData={LoginInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"h-14 w-full "}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />

                </div>
                <div className={'flex w-full justify-center items-center h-12'}>
                    <SVGraphics className={'h-full w-2/12 mr-2 mt-8 cursor-pointer'}
                                svgname={'back'}
                    onClick={()=>navigate('/login')}
                    />
                    <button type="button"
                            onClick={() => SubmitFunc()}
                            // disabled={!LoginInputs['Email']}
                            disabled={disabled}
                            className="text-white mt-8 w-10/12 h-12 bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                        Send
                    </button>

                </div>

                <div className={"w-full text-center mt-2"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>


            </div>

        </div>

    )
}
