import React, {useState} from "react";
import lungs from "../../../../assets/lungs.png"
import heart from "../../../../assets/heart.png"
import SVGraphics from "../../../../assets/SVGraphics";
import {GetExam} from "./GetExam";


export function BodyScans(props) {

    const [collapse, setcollapse] = useState([]);
    const [scanCollapse, setscanCollapse] = useState([]);
    const [audioId, setAudioId] = useState(null)
    const [currentAudio, setCurrentAudio] = useState("")
    const [prevAudio, setPrevAudio] = useState("")
    const [audio, setAudio] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [audioEnded, setAudioEnded] = useState(false)


    const openExam = (id) => {
        let examcollapse = [...collapse]
        let exam = examcollapse.indexOf(id)
        if (exam >= 0) {
            examcollapse.splice(exam, 1)
        } else {
            examcollapse.push(id)
        }
        setcollapse(examcollapse)
    }
    const openScan = (id) => {
        let allscanCollapse = [...scanCollapse]
        let scan = allscanCollapse.indexOf(id)
        if (scan >= 0) {
            allscanCollapse.splice(scan, 1)
        } else {
            allscanCollapse.push(id)
        }
        setscanCollapse(allscanCollapse)
    }
    const setPaused = (localAudio) => {
        if (isPlaying) {
            // localAudio.currentTime = 0;
            // setIsPaused(true)
            localAudio?.pause()
            setIsPlaying(false)
            setAudioEnded(true)
        }
    }
    const playAudio = (result) => {
        let localAudio = document.getElementById(String(result.index))
        let _prevAudio = document.getElementById(prevAudio)
        if (_prevAudio) {
            _prevAudio.pause()
            _prevAudio.currentTime = 0
        }
        let index = audioId?.index === result.index
        localAudio?.pause()
        localAudio.src = result.link
        localAudio.currentTime = 0;
        if (index && isPlaying) {
            setPaused()
        } else {
            localAudio.src = result.link
            setCurrentAudio(result.link)
            setIsPlaying(true)
            setAudioEnded(false)
            localAudio?.pause()
            localAudio.play();
        }
        setAudio(localAudio)
        setAudioId(result)
        setPrevAudio(String(result.index))


    }


    return (
        <div
            className="w-full font-Poppins mb-32 pb-[3rem]">
            {
                props.bodyScans.map(scan => {
                        return <div key={scan.id}>
                            <div className={"mt-6 text-[#657690] text-[0.875rem] font-Poppins ml-4"}>{scan.date}</div>
                            {
                                scan.exams.map((s, index) => {
                                    let examsLength = s.Examinations.length
                                    let bodyType = []
                                    s.Examinations.forEach(ex => {
                                        if (ex.points && ex.points.length > 0) {
                                            bodyType.push(ex.ExaminationName)
                                        }
                                    })
                                    // bodyType=bodyType.reverse()
                                    let allExams = examsLength === 2 ? s.Examinations[0].points.concat(s.Examinations[1].points) : examsLength === 1 ? s.Examinations[0].points : []
                                    return <div key={s.ExamID} className={""}>
                                        <div
                                            className={`flex w-full h-16 justify-between bg-white border border-[#EBF2FC] px-4 rounded-xl ${index === 0 ? 'mt-3' : ''}`}>
                                            <div className={"flex w-1/12"}>
                                                {
                                                    bodyType.map((t, index) => {
                                                        return <div key={t + index}
                                                                    className={`rounded-full border border-[#EBF2FC] ${index === 1 ? 'relative right-1.5' : 'z-[1]'} w-8 h-8 text-center flex justify-center content-center flex-col self-center bg-white `}>{
                                                            <img className={"w-4 self-center"}
                                                                 alt={t === "Heart Parts" ? "H" : "L"}
                                                                 src={t === "Heart Parts" ? heart : lungs}/>
                                                        }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div
                                                className={"self-center ml-3 text-titleColor text-base font-medium w-10/12 font-Poppins"}>{`${s.creationTime}, ${s.Examinations.length> 0 && s.Examinations[0].points.length > 0 ? s.Examinations[0].points.length + " " + s.Examinations[0].ExaminationName + " " : ""}  ${examsLength > 1 && s.Examinations[1].points.length > 0 ? "," + s.Examinations[1].points.length + " " + s.Examinations[1].ExaminationName : ""}`}</div>
                                            <div
                                                className={" h-full self-center w-1/12 cursor-pointer text-center flex flex-col justify-center"}
                                                onClick={() => openExam(s.ExamID)}>
                                                <SVGraphics className={"ml-14 mt-2 w-8 h-8"}
                                                            svgname={`${collapse.find(x => x === s.ExamID) ? 'arrowBlackUp' : 'arrowBlackDown'}`}/>
                                            </div>

                                        </div>
                                        {collapse.find(x => x === s.ExamID) &&
                                        <div className={"w-full bg-white border border-[#EBF2FC] px-4 rounded-b-xl p-4"}>
                                            {
                                                s.ExamNote &&
                                                <div className={"flex mb-4"}>
                                                    <SVGraphics className={" w-8 h-8"} svgname={"note"}/>
                                                    <div
                                                        className={"ml-2.5 w-full bg-white p-4 border border-[#EBF2FC] rounded-xl"}>
                                                        <div
                                                            className={"text-titleColor font-Poppins text-[0.875rem] ont-medium"}>Note
                                                        </div>
                                                        <div
                                                            className={"text-[#415576] font-Poppins text-[0.875rem]"}>{s.ExamNote}</div>

                                                    </div>

                                                </div>
                                            }
                                            {
                                                allExams.map(exam => {
                                                    if (exam.Results.length > 1) {
                                                        return <div key={exam.id} className={"mb-7"}>
                                                            <div className={"flex mb-7 "}>
                                                                <div
                                                                    className={"rounded-full w-8 h-8 bg-[#E5E8EC] text-center flex self-center justify-center flex-col text-[0.875rem] font-Poppins text-[#415576] font-semibold"}>{exam.Results.length}</div>
                                                                <div
                                                                    className={"ml-2.5 text-base font-Poppins text-titleColor font-medium "}>{exam.pointName}</div>
                                                                <SVGraphics onClick={() => openScan(exam.id)}
                                                                            className={`flex w-8 self-center cursor-pointer`}
                                                                            svgname={`${scanCollapse.find(x => x === exam.id) ? 'arrowBlackUp' : 'arrowBlackDown'}`}/>
                                                            </div>
                                                            {scanCollapse.find(x => x === exam.id) &&
                                                            exam.Results.map(result => {
                                                                return <GetExam
                                                                    key={result.pointID}
                                                                    result={result}
                                                                    name={result.bodyPart}
                                                                    currentAudio={currentAudio}
                                                                    // isPaused={isPaused}
                                                                    isPlaying={isPlaying}
                                                                    svgName={result.uploadedFiles.find(x => x.type === 'audio')?.index === audioId?.index && isPlaying ? 'pause' : 'play'}
                                                                    audio={audio}
                                                                    audioId={audioId}
                                                                    audioEnded={audioEnded}
                                                                    onClick={() => playAudio(result.uploadedFiles.find(x => x.type === 'audio'))}
                                                                    setPaused={(r) => setPaused()}
                                                                />
                                                            })
                                                            }
                                                        </div>
                                                    } else if (exam.Results.length === 1) {
                                                        return <div key={exam.id} className={"mb-7"}>
                                                            {exam.Results.map(result => {
                                                                return <GetExam
                                                                    key={result.pointID}
                                                                    result={result}
                                                                    name={exam.pointName}
                                                                    currentAudio={currentAudio}
                                                                    // isPaused={isPaused}
                                                                    isPlaying={isPlaying}
                                                                    svgName={result.uploadedFiles?.find(x => x.type === 'audio')?.index === audioId?.index && isPlaying ? 'pause' : 'play'}
                                                                    audio={audio}
                                                                    audioId={audioId}
                                                                    audioEnded={audioEnded}
                                                                    onClick={() => playAudio(result.uploadedFiles.find(x => x.type === 'audio'))}
                                                                    setPaused={(r) => setPaused()}
                                                                />
                                                            })
                                                            }
                                                        </div>
                                                    } else {
                                                        return ""
                                                    }
                                                })
                                            }
                                        </div>
                                        }
                                    </div>
                                })
                            }
                        </div>
                    }
                )
            }
        </div>
    )
}
