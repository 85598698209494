import React, {useState, useEffect} from "react";
import DeviceApi from "../../services/ApiServices/DeviceApi";
import {authenticationService} from "../../services/AuthinticationService";


const OrganizationAssignDeviceModal = ({onCancel, onSubmit}) => {

    const [organizationId, setOrganizationId] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const title='Assign Device'

    useEffect( () => {
        let user = authenticationService.currentUser
        setOrganizationId(user.source._value.profile.OrganizationID)
    }, [])

    const updateDevice = async () => {
        let device = {
            BLEMACAddress:searchValue,
            OrganizationID:organizationId
        }
        let submit
            submit =await DeviceApi.assignDevice(device)
            if(submit && submit.data) {
                onSubmit()
            }
    }

    const onChangeSearch = (event) => {
        let filterValue = event.target.value
        setSearchValue(filterValue)

    }



    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-2/5 h-auto max-w-xl md:h-auto self-center">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 ">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                {`Here you can assign device to your organization`}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-4/6 max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div className={`mx-auto h-3/6 w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full pb-8"}>

                                <>
                                    <div
                                        className={"w-full h-auto py-6 font-semibold text-lg"}>Device Details
                                    </div>

                                    <div className={`relative h-14 w-full m-auto `} >
                                        {/*<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                                        {/*    <SVGraphics className="text-gray-600 h-4 w-4 fill-current" svgname={'glass'} />*/}
                                        {/*</div>*/}
                                        <input type="search" id="default-search"
                                               onChange={event =>{onChangeSearch(event)}}
                                               value={searchValue}
                                               className={`block border border-[#A6C3F3] w-full p-4 pl-10 text-[0.875rem] text-gray-900 border border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-0 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                               placeholder="Assign Device" required/>
                                    </div>
                                    {/*{isDropDown && options.length>0 &&*/}
                                    {/*<div className={`relative h-auto w-full m-auto border border-[#A6C3F3] p-2 bg-white`} >*/}
                                    {/*    {*/}
                                    {/*        options.map(opt=>{*/}
                                    {/*            return <div onClick={()=>setValue(opt)}>{opt}</div>*/}
                                    {/*        })*/}
                                    {/*    }*/}

                                    {/*</div>*/}
                                    {/*}*/}

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() => updateDevice()}
                                    disabled={!searchValue}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {'Update'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default OrganizationAssignDeviceModal;
