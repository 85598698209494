import React from "react";
import SVGraphics from "../../../../assets/SVGraphics";
import {OverlayTrigger, Tooltip} from "react-bootstrap";



export function PatientCard(props) {

    let patient = JSON.parse(JSON.stringify(props.patient))

    return (
        <div
            className="w-full border border-[#EBF2FC] bg-white h-20 rounded-xl flex content-center flex-col justify-center font-Poppins">
            <div className={"w-full flex justify-center content-center h-full "}>
                <div
                    className={"rounded-full bg-[#EBF2FC] w-9 h-8 text-center text-[#588DE9] align-middle flex justify-center content-center flex-col m-4 self-center"}>
                    {patient['fullName']?.charAt(0).toUpperCase()}
                </div>
                <div className={"flex w-full"}>
                    {
                        Object.entries(patient).map(([key, value]) => {
                            return <div key={key} className={"w-fit flex justify-around self-center "}>
                                <div className={`${key==='fullName'?'pr-7':'px-7'}`}>
                                    <div className={"font-semibold text-base h-6"}>
                                        {value}
                                    </div>
                                    <div className={"text-[#657690] text-[0.875rem]"}>
                                        {key==='fullName'?'Full Name':key==='idNumber'?'ID Number':key==='age'?'Age':key==='phone'?'Phone':key==='email'?'Email':''}
                                    </div>
                                </div>
                                {key!=='email' &&
                                    <div className={"w-px bg-[#EBF2FC] h-8 self-center"}/>
                                }

                            </div>
                        })
                    }
                </div>
                 <OverlayTrigger
                    key={'bottom'}
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-${'bottom'}`}
                                 style={{
                                     position: 'absolute',
                                     backgroundColor: 'rgba(87,84,84,0.85)',
                                     padding: '2px 10px',
                                     color: 'white',
                                     borderRadius: 3,
                                     ...props.style,
                                 }}
                        >
                            <div>Hide Patient</div>
                        </Tooltip>
                    }
                >
                     <div>
                         <SVGraphics className={"w-8 self-center mr-8 relative top-2 cursor-pointer"} onClick={props.hidePatient} svgname={`blackEye`}/>
                     </div>

                </OverlayTrigger>

                <SVGraphics className={"w-8 self-center mr-8 cursor-pointer"} onClick={props.editPatient} svgname={`edit`}/>
            </div>
        </div>
    )
}
