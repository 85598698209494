import React from "react";
import SVGraphics from "../../assets/SVGraphics";

export function NavBarLogOutPopUp ({logoutUser}) {
    return (
        <div className={"flex justify-end w-48 h-16 top-2 justify-self-end self-end relative mr-44"}>
            <div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly p-2 border border-[#A6C3F3] ">
                {/*<div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'} ><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>*/}
                <div className={'flex justify-between h-1/2 cursor-pointer'} onClick={() => logoutUser()}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Logout</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`logOut`}/></div>
            </div>

        </div>
    )
}