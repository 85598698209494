import React, {useEffect, useRef, useState} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import logo from "../../../assets/SanollaLogo.png"
import formData from "../../forms/formsData.json";
import {SanollaForm} from "../../forms/SanollaForm";
import {useNavigate} from "react-router-dom";
import utils from "../../../utils/utils";
import {Error} from "../../forms/Error";
import Constants from "../../../utils/constants";
import { object, string } from 'yup';
import ReCaptcha from "./ReCaptcha";


export function Login() {
    const [LoginInputs, setLoginInputs] = useState({});
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState({'Password':false});
    const [isError, setIsError] = useState(false);
    const [formType, setFormType] = useState(formData['login'])
    const [disabled, setDisabled] = useState(true)
    const [count, setCount] = useState(true)
    const captchaRef = useRef();

    const loginSchema = object({
        Email: string().email("Please enter valid email").required().typeError("Email is Required"),
        Password: string().required().typeError("Password is Required"),
    })
    
    const [BackendErrors, setBackendErrors] = useState(null);

    useEffect(() => {
        setCount(!count)
    },[LoginInputs,error])

    const SubmitFunc = async (reCaptchaToken) => {
        // let captchaToken = captchaRef.current.getValue();
        console.log("SubmitFunc",reCaptchaToken)

            let response = await authenticationService.login(LoginInputs.Email, LoginInputs.Password,reCaptchaToken);
            if (response && response.status < 400) {
                let data = response.data
                if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                    // data.hasOwnProperty('role')
                    /*    if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true) {
                            const {from} = {from: {pathname: "/ChangePassword"}};
                            history.push(from);
                        } else if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false) {
                            const {from} = {from: {pathname: "/dashboard"}};
                            history.push(from);
                        } else*/
                    if(data.profile.PassNeedsToChange){
                        navigate('/newPassword')
                    }else{
                        navigate(utils.navigateAccordingToRole(data.hasOwnProperty('role') && data['role'][0]))
                    }

                } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa') {
                    const {from} = {from: {pathname: "/verificationCode"}};
                    navigate(from);
                } else {
                    setLoginInputs({
                        Email: "",
                        Password: ""
                    })
                }
            } else {
                setLoginInputs({
                    Email: "",
                    Password: ""
                })
                setBackendErrors('Wrong Email or Password')
                setBackendErrors(response.response.data.message)
            }
        // }
    }
    
    const handleChange = async (event) => {
        let inputs = {...LoginInputs}
        let name = event.target.name
        let value = event.target.value
        inputs[name]=value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        loginSchema.validate(inputs).then(function() {
                formErrors[name] = false
                setDisabled(false)
            })
            .catch(function(err) {
                if(err){
                    setIsError(true)
                    let index = form.findIndex(x=>x.name===name)
                    form[index]['error'] = err.errors
                    if(err.path===name){
                        formErrors[name] = true
                        setDisabled(true)
                    }else{
                        formErrors[name] = false
                    }
                }
                else{
                    formErrors[name] = false
                    setDisabled(false)
                }
            });
         setLoginInputs(inputs)
         setFormType(form)
         setError(formErrors)
        setBackendErrors(null)
    }
    const setShowPasswordType = () => {
        let isShow ={...showPassword}
        isShow['Password'] = !isShow['Password']
        setShowPassword(isShow)

    }
    const ExecuteRecaptcha = async (reCaptchaToken) => {
        console.log("ExecuteRecaptcha")
        // await captchaRef.reset()
         await captchaRef.current.reset()
        await captchaRef.current.execute()
    }



    return (
        <div className={"w-[28rem] h-[30.75rem] m-auto bg-white shadow-2xl rounded-3xl flex items-center justify-center relative top-44 pt-4"}>
            <div className={"flex flex-col justify-center w-4/6"}>
                {/*<TextSanolla text={"Login"} className={"text-5xl flex justify-center"}/>*/}
                <img src={logo} alt={"Sanolla"} className={"flex w-36 self-center"}/>

                <div className={"mt-12"}>
                    <SanollaForm
                        data={formType}
                        formData={LoginInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"h-14 w-full "}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        type={showPassword}
                        showOnClick={(e)=>setShowPasswordType(e)}
                        isError={isError}
                    />
                    <div className={"w-full text-right relative mb-2.5 text-titleColor cursor-pointer text-[0.875rem] font-Poppins"} onClick={()=>navigate('/forgotPassword')}>Forgot password?</div>
                </div>
                <button type="button"
                        // onClick={() => ExecuteRecaptcha()}
                        onClick={() => SubmitFunc()}
                        //disabled={!LoginInputs['Email'] || !LoginInputs['Password'] || (LoginInputs['Email'] && !utils.mailValidation(LoginInputs['Email']))}
                        disabled={disabled}
                        className="text-white mt-8 h-12 bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                    Sign In
                </button>
                <div className={"w-full text-center mt-2"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>

                <ReCaptcha
                    refReCaptcha={captchaRef}
                    action={async (reCaptchaToken) => {
                        await SubmitFunc(reCaptchaToken)
                    }}
                    setErr={console.log}/>



            </div>

        </div>

    )
}
