import React from "react";
import {TextSanolla} from "./TextSanolla";

export function SelectSanolla(props) {

    return (
        <div className={props.containerClassName}>

            <select className={props.inputClassName} name={props.name} disabled={props.disabled} value={props.value} onChange={props.onChange}>
                <option value={''}>{props.placeholder}</option>
                {props.data.map(data=>{
                    return <option key={data.value} value={data.value} >{data.label}</option>
                })
                }
            </select>
            <TextSanolla text={props.label} className={props.labelClassName}/>
        </div>
    )
}
