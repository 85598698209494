import React, {useCallback, useEffect, useState} from "react";
import { useNavigate, useParams} from 'react-router-dom';
import {SearchInput} from "../../../input/SearchInput";
import SuperAdminApi from "../../../../services/ApiServices/SuperAdminApi";
import {OrganizationDetails} from "./OrganizationDetails";
import AddAdmineModal from "../../../modals/AddAdminModal";
import AddContactPersonModal from "../../../modals/AddContactPersonModal";
import {TechnicianTable} from "../TechnicianTable";
import EditOrganizationModal from "../../../modals/EditOrganizationModal";
import {makeWarning} from "../../../modals/WarningModal";



export function OrganizationCard() {
    const [organization, setOrganization] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [contactPerson, setContactPerson] = useState({});
    const [admin, setAdmin] = useState({});
    const [technicians, setTechnicians] = useState([]);
    const [filteredTechnicians, setFilteredTechnicians] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [organizationId, setOrganizationId] = useState(null);
    const [isOpen, setIsOpen] = useState(null);
    const [href, setHref] = useState(null);
    const [isEditOrganization, setIsEditOrganization] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const navigate = useNavigate();
    let params  = useParams();

    const navigateBackToOrganizations = () => {
        navigate('/sanollaAdmin/Organizations')
    }
    const fetchData = async (id) => {

        let organization = await SuperAdminApi.getOrganization(id)
        if (organization && organization.data) {
            let user = organization.data
            setOriginalData(user)
            setOrganization(user)
            setUserData(user)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        let id = params.id
        if (id) {
            setOrganizationId(id)
            fetchData(id).then()
            const getTechnicians = async () =>{
                let fetchTechs = await SuperAdminApi.getAllTechnician(id)
                if (fetchTechs && fetchTechs.data) {
                    let data =[]
                    fetchTechs.data.forEach(technician=>{
                        data.push(technician.PII)
                    })
                    setTechnicians(data)
                    setFilteredTechnicians(data)
                }
            }
            getTechnicians(id).then()
        }
    }, [params.id])



    const setUserData = (data) => {
        let org = {}
        let contact ={}
        let admin ={}
        if (data) {
            org = {
                name: data.Name,
                address: data.Address,
                number: data.OrganizationNumber,
                type: data.OrganizationType,
            }
            contact={
                title: 'Contact Person',
                name: data.ContactPersonName,
                phone: data.ContactPersonPhone,
                email: data.ContactPersonEmail,
            }
            admin={
                title: 'Admin',
                name: data.admin.FirstName?data.admin.FirstName: " "+data.admin.LastName?data.admin.LastName:"" ,
                phone: data.admin.PhoneNumber,
                email: data.admin.Email,
            }
        }
        setOrganization(org)
        setContactPerson(contact)
        setAdmin(admin)
        setOrganizationId(data.OrganizationID)
    }

    // const handleShowModal = useCallback(() => {
    //     setShowModal(!showModal);
    //
    //     props.closeModal()
    // }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setCloseModal(false)
        setIsEditOrganization(false);

    }, []);

    const handleEditOrganization = (type) => {
        setCloseModal(false)
        if (type) {
            fetchData(organizationId).then()
            if (type==='org')
                handleCloseModal()
            else if(type==='con')
                setShowContactModal(false)
            else if(type==='admin')
                setShowAdminModal(false)
        }
    }

    // const removeOrganization = () => {
    //     console.log("removeOrganization")
    // }

    const sendEmail = (type) => {
        let email = type==='admin'?admin.email:contactPerson.email
        setHref(email)


    }
    const handleSearch = (event) => {
        let filterValue = event.target.value
        let filteredData = []
        for (let i = 0; i < technicians.length; i++) {
            filteredData = technicians.filter(user =>
                String(user['FirstName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['LastName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['PhoneNumber']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Email']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredTechnicians(filteredData)
    }
    const removeContactPerson = async () => {
        setCloseModal(true)
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this Contact person?",message:"This Contact person will be hidden immediately, you can’t undo this action."});
        if(message) {
            let deleteContact = await SuperAdminApi.deleteOrganizationContactPerson(organizationId)
            if (deleteContact) {
                fetchData(organizationId).then()
            }
        }
        setCloseModal(false)
    }

    return (
        <div
            className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 font-Poppins">
            <div onClick={() => navigateBackToOrganizations()}
                 className={"cursor-pointer text-[#1D355C] text-base"}> &#10094; &nbsp; Back
            </div>
            <span className={'flex w-full justify-between items-center mt-5'}>
                {/*<TextSanolla text={"Patient"} className={"font-medium text-titleColor text-xl  "}/>*/}
            </span>
            {/*organization*/}
            <OrganizationDetails
                                 editOrganization={() => setIsEditOrganization(true)}
                                 isEdit={true}
                                 data={organization}
                                 isOpen={isOpen==='organization'}
                                 openMenu={()=>setIsOpen('organization')}
                                 closeModal={closeModal}
                                 // deleteOrganization={() => removeOrganization()}
                                 type={'or'}//organization
            />
            {/*admin*/}
            <OrganizationDetails
                                 // editOrganization={() => setIsEditOrganization(true)}
                                 isEdit={true}
                                 data={admin}
                                 isOpen={isOpen==='admin'}
                                 edit={() => {
                                     setShowAdminModal(true)
                                     setCloseModal(true)
                                 }}
                                 href={href}
                                 openMenu={()=>setIsOpen('admin')}
                                 closeModal={closeModal}
                                 sendEmail={()=>sendEmail('admin')}
                                 type={'ad'}
            />
            {/*contact person*/}
            <OrganizationDetails
                                 // editOrganization={() => setIsEditOrganization(true)}
                                 isEdit={true}
                                 data={contactPerson}
                                 edit={()=>{setShowContactModal(true)
                                 setCloseModal(true)
                                 }}
                                 delete={()=>removeContactPerson()}
                                 closeModal={closeModal}
                                 sendEmail={()=>sendEmail('contact')}
                                 href={href}
                                 type={'cp'}
            />
            <div className={"flex justify-between mt-6 items-center w-full"}>
                <div className={'flex justify-center h-fit items-center'}>
                    <div
                        className={`block pr-4 text-blue-700 md:bg-transparent  md:p-0 cursor-pointer flex`}
                        aria-current="page">{"Technician"}</div>
                    <div
                        className={'ml-2 rounded-full bg-[#EBF2FC] h-fit min-w-[1.25rem] w-fit flex text-blue-700 text-center flex-col justify-center py-0 px-2 '}>{technicians.length}</div>
                </div>

                {/*<div className={'w-2/4 h-3/4'}>*/}
                <div className={'flex mt-4 justify-self-end w-full'}>
                    <SearchInput
                        containerStyle={{width:'70%'}}
                        inputClass={'h-8'}
                        onChange={(event)=>handleSearch(event)}
                    />

                    {/*<ButtonSanolla onClick={handleShowModal}*/}
                    {/*               containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-sm border border-sanollaButtonColor'}*/}
                    {/*               label={`+ Add Technician`}/>*/}
                </div>
            </div>
            <div className={"h-[22rem]"}>
                        <TechnicianTable
                            isLoading={isLoading}
                        data={filteredTechnicians?filteredTechnicians:[]}
                        />
            </div>


            {showAdminModal &&
            <AddAdmineModal onCancel={()=>{setShowAdminModal(false)
                                     setCloseModal(false)
                                 }}
                            onSubmit={()=>handleEditOrganization('admin')}
                            organizationId={organizationId}
                            isEdit={true}
                entry={originalData['admin']}
            />}
            {showContactModal &&
            <AddContactPersonModal onCancel={()=>{setShowContactModal(false)
            setCloseModal(false)
            }}
                // onSubmit={(editPatient)=>handleEditPatient(editPatient)}
                                   onSubmit={()=>handleEditOrganization('con')}
                                    organizationId={organizationId}
                                    entry={originalData}
            />}

            {isEditOrganization &&
            <EditOrganizationModal onCancel={handleCloseModal}
                                  onSubmit={()=>handleEditOrganization('org')}
                                  organization={organization}
                                  entry={{OrganizationID:organizationId}}
            />}


        </div>
    )
}
