import React, {useState, useEffect} from "react";
import DeviceApi from "../../services/ApiServices/DeviceApi";
import {SelectSanolla} from "../input/SelectSanolla";
import formData from "../forms/formsData.json";
import {SanollaForm} from "../forms/SanollaForm";
import Constants from "../../utils/constants";
import {mixed, object, string} from "yup";
import {logDOM} from "@testing-library/react";
import SuperAdminApi from "../../services/ApiServices/SuperAdminApi";



const AddNewSoftwareModal = ({onCancel, onSubmit,entry, data,type,saveVersions}) => {

    const [inputsForms, setInputsForm] = useState({})
    const [fileName, setFileName] = useState('SW version')
    const [fileSrc, setFileSrc] = useState(null)
    const [file, setFile] = useState(null)
    const [options, setOptions] = useState([])
    const [label, setLabel] = useState('Version')
    const [name, setName] = useState('OrganizationID')
    const [title, setTitle] = useState('Add Software Version')
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [formType, setFormType] = useState(formData['add_SW'])

    const versionSchema = object().shape({
        version: string().required().typeError('Please insert SW Version').default(""),

    });
    const fileSchema = object().shape({
        files: mixed().required('A file is required')
            .test('fileType', 'Unsupported file type', (value) => {
                if (!value) return true; // Skip validation if no file provided
                return ['enc'].includes(value.type); // Specify the supported file types
            }),
    });
    // useEffect( () => {
    //     let _device = {}
    //     setOptions(data)
    //     if(type==='assign'){
    //         _device={
    //             BLEMACAddress:entry['BLEMACAddress'],
    //             OrganizationID:entry['OrganizationID']
    //         }
    //         setTitle('Assign Device')
    //         setLabel('Assign To')
    //         setName('OrganizationID')
    //     }else{
    //         setTitle('Push SW update')
    //         setLabel('SW Version*')
    //         setName('SW')
    //     }
    //     setDevice(_device)
    //  // let modalType =
    // }, [type,data,entry])


    // const updateDevice = async () => {
    //     let submit
    //     if(type==='assign'){
    //         submit =await DeviceApi.assignDevice(device)
    //         if(submit && submit.data){
    //             onSubmit(submit.data.patient)
    //         }
    //     }else{
    //         submit =await DeviceApi.addDevice(device)
    //         if(submit && submit.data){
    //             onSubmit()
    //         }
    //     }
    //
    // }
    const onChange = async (e) => {
        let inputs = {...inputsForms}
        let form = [...formType]
        let formErrors = {...error}
        let _name = e.target.name
        let _files
        let schema
        let data

        if(_name==="files") {
            if (e.target.files && e.target.files[0]) {
                setFileName(e.target.files[0]['name'])
                _files=e.target.files[0]
                setFileSrc(URL.createObjectURL(e.target.files[0]));
                setFile(_files)
                schema=fileSchema
                data=_files
            }
        }else{
            let value = e.target.value
            if (value === "") {
                value = null
            }
            inputs[e.target.name] = value
            data=inputs
            schema=versionSchema
            setInputsForm(inputs)

        }
        await schema.validate(data,{abortEarly: false}).then(function(value) {
            formErrors[_name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===_name)
                err.inner.forEach(error=>{
                    if(error.path===_name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===_name)
                if(errorIndex===-1){
                    formErrors[_name] = false
                    form[index]['error'] = []
                }
            });



        setFormType(form)
        setError(formErrors)
    }

    let isShown=false
    // if(data['OrganizationName'] && data['OrganizationName']!== ""){
    //     isShown=true
    // }
    const AddSWVersion = async () => {
        let AddSWVersion = await DeviceApi.addNewSoftware(inputsForms,file)
        if(AddSWVersion && AddSWVersion.data){
            saveVersions()
        }


    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-auto max-w-xl md:h-auto self-center">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 ">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                {`Here you can ${type==='assign'?'assign device to organization':'Push SW update'}`}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[27rem] max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div className={`mx-auto h-[27rem w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full pb-8"}>
                                <SanollaForm
                                    data={formType}
                                    formData={inputsForms}
                                    onChange={onChange}
                                    containerClassName={"h-14 w-full mt-8"}
                                    inputClassName={Constants.inputClass}
                                    isShown={error}
                                    isError={isError}
                                />
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    disabled={!inputsForms.version || !file}
                                    onClick={() => AddSWVersion()}
                                    // disabled={type==='assign'?!device['BLEMACAddress']:false}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {'Submit'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddNewSoftwareModal;
