import React from "react";

class SVGraphics extends React.Component {

    getViewBox = svgname => {
        switch (svgname) {
            case "glass":
                return "0 0 30 30";
            case "organizations":
                return "0 0 24 24";
            case "clinicians":
                return "0 0 24 24";
            case "devices":
                return "0 0 24 24";
            case "fail":
                return "0 0 35 35";
            case "play":
                return "0 0 35 35";
            case "pause":
                return "0 0 35 35";
            case "arrowBlackUp":
                return "0 0 35 35";
            case "arrowBlackDown":
                return "0 0 35 35";
            case "edit":
                return "0 0 24 24";
                case "update":
                return "0 0 24 24";
            case "blackEye":
                return "0 0 24 24";
                case "logOut":
                return "0 0 24 24";
            case "trash":
                return "0 0 24 24";
                case "email":
                return "0 0 24 24";
            case "note":
                return "0 0 35 35";
            case "eye":
                return "0 0 16 16";
                case "eyeLine":
                return "0 0 16 16";
            case "link":
                return "0 0 32 32";
            case "files":
                return "0 0 72 72";
                case "back":
                return "0 0 48 48";

            default:
                return "0 0 24 24";

        }
    }


    getPath = (svgname) => {
        switch (svgname) {
            case "glass":
                return (
                    <g fill="none">
                        <path stroke="#0957DE" strokeWidth="2"
                              d="M14 23c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9z"/>
                        <path stroke="#0957DE" strokeLinecap="round" strokeWidth="2" d="M22.867 22.775l3.768 3.768"/>
                    </g>
                );
                case "email":
                return (
                    <g fill="none">
                        <path d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 6L12.2571 13L21 6" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
                case "back":
                return (
                    <g fill="none">
                        <path d="M26 20L22 24L26 28" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#CEDDF8"/>
                    </g>
                );
                case "update":
                return (
                    <g fill="none">
                    <path d="M10 16H5V21" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19.4176 14.9971C18.8569 16.3848 17.9182 17.5874 16.7081 18.4682C15.498 19.3491 14.065 19.8727 12.5721 19.9797C11.0792 20.0868 9.58624 19.7728 8.26287 19.0736C6.9395 18.3744 5.83882 17.318 5.08594 16.0244" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14 8H19V3" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.58203 9.00315C5.14272 7.61541 6.08146 6.41278 7.29157 5.53197C8.50169 4.65116 9.93462 4.12749 11.4275 4.02048C12.9204 3.91346 14.4134 4.2274 15.7368 4.92661C17.0601 5.62582 18.1608 6.68226 18.9137 7.97584" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                        );
            case "organizations":
                return (
                    <g clipPath="url(#clip0_1959_1637)">
                        <path d="M2 20H22" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M7 10H11" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M20 20V20.75C20.4142 20.75 20.75 20.4142 20.75 20H20ZM14 20H13.25C13.25 20.4142 13.5858 20.75 14 20.75V20ZM15 9.75H19V8.25H15V9.75ZM19.25 10V20H20.75V10H19.25ZM20 19.25H14V20.75H20V19.25ZM14.75 20V10H13.25V20H14.75ZM19 9.75C19.1381 9.75 19.25 9.86193 19.25 10H20.75C20.75 9.0335 19.9665 8.25 19 8.25V9.75ZM15 8.25C14.0335 8.25 13.25 9.0335 13.25 10H14.75C14.75 9.86193 14.8619 9.75 15 9.75V8.25Z"
                            fill="#0957DE"/>
                        <path d="M7 7L11 7" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M14 20V20.75C14.4142 20.75 14.75 20.4142 14.75 20H14ZM4 20H3.25C3.25 20.4142 3.58579 20.75 4 20.75V20ZM5 3.75H13V2.25H5V3.75ZM13.25 4V20H14.75V4H13.25ZM14 19.25H4V20.75H14V19.25ZM4.75 20V4H3.25V20H4.75ZM13 3.75C13.1381 3.75 13.25 3.86193 13.25 4H14.75C14.75 3.0335 13.9665 2.25 13 2.25V3.75ZM5 2.25C4.0335 2.25 3.25 3.0335 3.25 4H4.75C4.75 3.86193 4.86193 3.75 5 3.75V2.25Z"
                            fill="#0957DE"/>
                    </g>
                );
            case "clinicians":
                return (
                    <g fill="none">
                        <path d="M4 21C4 18.2386 7.58172 16 12 16C16.4183 16 20 18.2386 20 21" stroke="#1D355C"
                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "devices":
                return (
                    <g fill="none">
                        <path
                            d="M6.81201 4C6.35686 4 5.95913 4.30736 5.84434 4.7478L3.03232 15.5375C3.01086 15.6198 2.99714 15.705 3.00877 15.7893C3.05036 16.0906 3.23745 16.3598 3.51962 16.5141L11.4804 20.8681C11.8019 21.044 12.1981 21.044 12.5196 20.8681L20.4804 16.5141C20.7626 16.3598 20.9496 16.0906 20.9912 15.7893C21.0029 15.705 20.9891 15.6198 20.9677 15.5375L18.1557 4.7478C18.0409 4.30736 17.6431 4 17.188 4L6.81201 4Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "fail":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="15.5" stroke="#FFDADF"/>
                        <path d="M12.5 19.5L19.5 12.5" stroke="#FF8393" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M19.5 19.5L12.5 12.5" stroke="#FF8393" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "play":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#E5F9F3"/>
                        <path
                            d="M12 11.7788C12 11.0022 12.848 10.5232 13.5131 10.9241L20.5172 15.1453C21.1609 15.5333 21.1609 16.4668 20.5172 16.8548L13.5131 21.0759C12.848 21.4768 12 20.9978 12 20.2212V11.7788Z"
                            fill="#1DC198" stroke="#1DC198" strokeWidth="1.33063" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>

                );
            case "arrowBlackDown":
                return (
                    <g fill="none">
                        <path d="M11 10L15 14L19 10" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "arrowBlackUp":
                return (
                    <g fill="none">
                        <path d="M11 14L15 10L19 14" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "edit":
                return (
                    <g fill="none">
                        <path
                            d="M4 19.9998H8L19.2929 8.70696C19.6834 8.31643 19.6834 7.68327 19.2929 7.29274L16.7071 4.70696C16.3166 4.31643 15.6834 4.31643 15.2929 4.70696L4 15.9998V19.9998Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "blackEye":
                return (
                    <g fill="none">
                        <path
                            d="M2.58631 8.77881C4.36575 10.5478 7.46904 12.9999 10.9997 12.9999C14.5303 12.9999 17.6331 10.5478 19.4125 8.77881C19.8818 8.31226 20.1172 8.07819 20.2667 7.62012C20.3733 7.29328 20.3733 6.70674 20.2667 6.3799C20.1172 5.92181 19.8818 5.6877 19.4125 5.22111C17.633 3.45208 14.5303 1 10.9997 1C7.46904 1 4.36575 3.45208 2.58631 5.22111C2.11665 5.68802 1.8818 5.92165 1.73231 6.3799C1.62569 6.70673 1.62569 7.29328 1.73231 7.62012C1.8818 8.07837 2.11665 8.31189 2.58631 8.77881Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M8.99951 7C8.99951 8.10457 9.89494 9 10.9995 9C12.1041 9 12.9995 8.10457 12.9995 7C12.9995 5.89543 12.1041 5 10.9995 5C9.89494 5 8.99951 5.89543 8.99951 7Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
                case "logOut":
                return (
                    <g fill="none">
                        <path d="M12 15L15 12M15 12L12 9M15 12L4 12" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 7V5C9 4.44772 9.44772 4 10 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H10C9.44771 20 9 19.5523 9 19V17" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "note":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#FFF4CC"/>
                        <path
                            d="M20.667 10.667H11.3337C10.9655 10.667 10.667 10.9655 10.667 11.3337V20.667C10.667 21.0352 10.9655 21.3337 11.3337 21.3337H17.0575C17.2343 21.3337 17.4039 21.2634 17.5289 21.1384L21.1384 17.5289C21.2634 17.4039 21.3337 17.2343 21.3337 17.0575V11.3337C21.3337 10.9655 21.0352 10.667 20.667 10.667Z"
                            stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21.3337 16.667H17.3337C16.9655 16.667 16.667 16.9655 16.667 17.3337V21.3337"
                              stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "trash":
                return (
                    <g fill="none">
                        <path d="M13.6665 10.334L13.6665 16.1673" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.3335 10.334L10.3335 16.1673" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M17 7H7V18.6667C7 19.1269 7.3731 19.5 7.83333 19.5H16.1667C16.6269 19.5 17 19.1269 17 18.6667V7Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M5.3335 7H18.6668" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M14.4998 4.5H9.49984C9.0396 4.5 8.6665 4.8731 8.6665 5.33333V7H15.3332V5.33333C15.3332 4.8731 14.9601 4.5 14.4998 4.5Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "eye":
                return (
                    <g fill="none">
                        <path
                            d="M1.21956 8.82269C0.926816 8.31282 0.926814 7.6861 1.21955 7.17623C2.75984 4.49343 5.22354 2.75586 8.00001 2.75586C10.7765 2.75586 13.2402 4.4934 14.7804 7.17617C15.0732 7.68604 15.0732 8.31276 14.7804 8.82264C13.2402 11.5054 10.7765 13.243 7.99999 13.243C5.22353 13.243 2.75985 11.5055 1.21956 8.82269Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>
                        <path
                            d="M9.96628 7.99942C9.96628 9.08539 9.08592 9.96575 7.99994 9.96575C6.91397 9.96575 6.03361 9.08539 6.03361 7.99942C6.03361 6.91344 6.91397 6.03308 7.99994 6.03308C9.08592 6.03308 9.96628 6.91344 9.96628 7.99942Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>

                    </g>

                );
                case "eyeLine":
                return (
                    <g fill="none">
                        <path
                            d="M1.21956 8.82269C0.926816 8.31282 0.926814 7.6861 1.21955 7.17623C2.75984 4.49343 5.22354 2.75586 8.00001 2.75586C10.7765 2.75586 13.2402 4.4934 14.7804 7.17617C15.0732 7.68604 15.0732 8.31276 14.7804 8.82264C13.2402 11.5054 10.7765 13.243 7.99999 13.243C5.22353 13.243 2.75985 11.5055 1.21956 8.82269Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>
                        <path
                            d="M9.96628 7.99942C9.96628 9.08539 9.08592 9.96575 7.99994 9.96575C6.91397 9.96575 6.03361 9.08539 6.03361 7.99942C6.03361 6.91344 6.91397 6.03308 7.99994 6.03308C9.08592 6.03308 9.96628 6.91344 9.96628 7.99942Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>
                        <line x1="0" y1="0" x2="100" y2="100" vectorEffect="non-scaling-stroke" stroke="#8996AA" />
                    </g>

                );
            case "link":
                return (
                    <g fill="none">
                        <path d="M9.17188 14.8287L14.8287 9.17188" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M7.05014 11.293L5.63593 12.7072C4.07383 14.2693 4.07383 16.8019 5.63593 18.364C7.19803 19.9261 9.73069 19.9261 11.2928 18.364L12.707 16.9498"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M11.293 7.05063L12.7072 5.63642C14.2693 4.07432 16.8019 4.07432 18.364 5.63642C19.9261 7.19851 19.9261 9.73117 18.364 11.2933L16.9498 12.7075"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "files":
                return (
                    <g fill="none">
                        <path
                            d="M43.3333 70L13.3333 70C11.4924 70 10 68.5076 10 66.6667L10 23.3333C10 21.4924 11.4924 20 13.3333 20L31.9526 20C32.8367 20 33.6845 20.3512 34.3097 20.9763L45.6904 32.357C46.3155 32.9821 46.6667 33.83 46.6667 34.714V66.6667C46.6667 68.5076 45.1743 70 43.3333 70Z"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M33.333 20L33.333 13.3333C33.333 11.4924 34.8254 10 36.6663 10L55.2856 10C56.1697 10 57.0175 10.3512 57.6427 10.9763L69.0234 22.357C69.6485 22.9821 69.9997 23.83 69.9997 24.714V56.6667C69.9997 58.5076 68.5073 60 66.6663 60L46.6663 60"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M46.6667 36.6667L33.3333 36.6667C31.4924 36.6667 30 35.1743 30 33.3333L30 20"
                              stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M69.9997 26.6667L56.6663 26.6667C54.8254 26.6667 53.333 25.1743 53.333 23.3333L53.333 10"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "pause":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#E5F9F3"/>
                        <path
                            d="M0 16C0 19.1645 0.938383 22.2579 2.69649 24.8891C4.45459 27.5203 6.95345 29.5711 9.87707 30.7821C12.8007 31.9931 16.0177 32.3099 19.1214 31.6926C22.2251 31.0752 25.0761 29.5513 27.3137 27.3137C29.5513 25.0761 31.0752 22.2251 31.6926 19.1214C32.3099 16.0177 31.9931 12.8007 30.7821 9.87706C29.5711 6.95345 27.5203 4.45459 24.8891 2.69649C22.2579 0.938384 19.1645 -3.77363e-08 16 0V2.02191C18.7646 2.02191 21.4671 2.84171 23.7658 4.37764C26.0645 5.91357 27.8561 8.09665 28.9141 10.6508C29.972 13.205 30.2489 16.0155 29.7095 18.727C29.1702 21.4385 27.8389 23.9291 25.884 25.884C23.9291 27.8389 21.4385 29.1702 18.727 29.7095C16.0155 30.2489 13.205 29.972 10.6508 28.9141C8.09665 27.8561 5.91357 26.0645 4.37764 23.7658C2.84171 21.4671 2.02191 18.7646 2.02191 16H0Z"
                            fill="#18A280"/>
                        <path
                            d="M18 11.667C18 11.1147 18.4477 10.667 19 10.667H21C21.5523 10.667 22 11.1147 22 11.667V20.3337C22 20.8859 21.5523 21.3337 21 21.3337H19C18.4477 21.3337 18 20.8859 18 20.3337V11.667Z"
                            fill="#1DC198"/>
                        <path
                            d="M10 11.667C10 11.1147 10.4477 10.667 11 10.667H13C13.5523 10.667 14 11.1147 14 11.667V20.3337C14 20.8859 13.5523 21.3337 13 21.3337H11C10.4477 21.3337 10 20.8859 10 20.3337V11.667Z"
                            fill="#1DC198"/>
                    </g>

                );

            default:
                return ""
        }
    }

    render() {
        let {
            name = null,
            svgname = "",
            style = {},
            onClick = null,
            fill = "rgb(0,0,0)",
            viewBox = "",
            width = "100%",
            className = "mhd-icons",
            subClass = "",
            size = '',
            id='',
            height = "100%",
            onMouseEnter,
            onMouseLeave,
            onMouseDown,
            onMouseUp
        } = this.props
        return <svg
            name={name ? name : 'oppa'}
            width={width}
            style={style}
            height={height}
            id={id}
            onMouseEnter={e => !!onMouseEnter ? onMouseEnter(e) : {}}
            onMouseLeave={e => !!onMouseLeave ? onMouseLeave(e) : {}}
            onMouseDown={e => !!onMouseDown ? onMouseDown(e) : {}}
            onMouseUp={e => !!onMouseUp ? onMouseUp(e) : {}}
            onClick={onClick}
            type={"image/svg+xml"}
            className={`${className} ${size} ${subClass}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || this.getViewBox(svgname)}
            fill={fill === "" ? "rgb(0,0,0)" : fill}
        >
            {this.getPath(svgname, this.props)}
        </svg>
    }
}

export default SVGraphics;
