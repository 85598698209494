import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import DeviceApi from "../../services/ApiServices/DeviceApi";
import Constants from "../../utils/constants"
import {object, string} from "yup";


const AddDeviceModal = ({onCancel, onSubmit, isEdit, entry}) => {

    const [device, setDevice] = useState({})
    const [type, setType] = useState(null)
    const [deviceType, setDeviceType] = useState([])
    const [swVersions, setSwVersions] = useState([])
    const [title, setTitle] = useState('Add Device')
    const [formType, setFormType] = useState(formData['add_Device'])
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [disabled, setDisabled] = useState(true)
    // if(isEdit){
    //     formType[3]['data'] = deviceType
    // }else{
        formType[0]['data'] = deviceType
    // }
    const deviceDetails = [{name:'LicenseValidityCode',label:'License validity codes'},{name:'SWVersion',label:'Software version'},{name:'DeviceVersionStatus',label:'Version Status'}]
    const deviceSchema = object().shape({
        BLEMACAddress: string().required().typeError('Please insert BLE MAC Add').default(""),
        Type: string().required('Please insert Type').typeError('Please insert Type').default(""),
        UBootVersion: string().optional().nullable().default(""),
        KernelVersion: string().optional().nullable().default(""),
        AudioControlVersion: string().optional().nullable().default(""),
        BluetoothFirmwareApplicationVersion: string().optional().nullable().default(""),
        SN: string().required().default("").typeError('Please insert SN'),
        WarrantyExpiration: string().optional().nullable().default(""),
    });

    useEffect(() => {
        // getDevicesTypes
        const fetchData = async () => {
            return await DeviceApi.getDevicesTypes()
        }
        // const getSWVersion = async () => {
        //     return await DeviceApi.getDevicesSWversions()
        // }
        fetchData().then(device => {
            if (device && device.data) {
                let types = [...new Set(device.data)]
                let _deviceType = []
                types.forEach(t => {
                    _deviceType.push({
                        "value": t,
                        "label": t
                    })
                })
                setDeviceType(_deviceType)
            }
        })
        // getSWVersion().then(device => {
        //     if (device && device.data) {
        //         let swVersions = [...new Set(device.data)]
        //         let _swVersions = []
        //         swVersions.forEach(t => {
        //             _swVersions.push({
        //                 "value": t,
        //                 "label": t
        //             })
        //         })
        //         setSwVersions(_swVersions)
        //     }
        // })
        const getDevice = async () => {
            return await DeviceApi.getDevice(entry.BLEMACAddress)
        }
        if (isEdit) {
            setFormType(formData['edit_Device'])
            setTitle('Edit Device')
            setType('edit')
            getDevice().then(d=>{
                setDevice(d.data[0])
            })

        }

    }, [])


    const createDevice = async () => {
        let submit
        if (isEdit) {
            submit = await DeviceApi.updateDevice(device, device.BLEMACAddress)
            if (submit && submit.data) {
                onSubmit()
            }
        } else {
            submit = await DeviceApi.addDevice(device)
            if (submit && submit.data) {
                onSubmit()
            }
        }
    }
    const onChange = async (e) => {
        let newDevice = {...device}
        let name = e.target.name
        let value = e.target.value
        let isErrorForm = {...isError}
        // let formData = {...FormData}
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        newDevice[name] = value
        await deviceSchema.validate(newDevice,{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                }
            });
        setDevice(newDevice)
        setFormType(form)
        setError(formErrors)
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:top-0 md:left-0 md:right-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-full max-w-xl md:h-modal self-center top-0 md:top-16">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 top-0">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">
                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">
                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                {`Carefully fill out the device's information and
                                click "${isEdit?'Update':'Create'}"`}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[40rem] max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-[38rem] w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>
                            <div className={"w-full pb-4"}>
                                {isEdit &&
                                    <>
                                    <div
                                        className={"w-full h-auto py-[1rem] font-semibold text-lg "}>Device Details
                                    </div>
                                {
                                    deviceDetails.map(detail=>{
                                        let value = ""
                                        if(detail.name==='LicenseValidityCode'){
                                            value=device[detail.name]?<a
                                                className={'text-blue underline decoration-1'}
                                                href={device[detail.name]}
                                                target="_blank"
                                                rel="noopener"
                                                download={`License_Validity_Code.bat`}
                                            >Download ValidityCode</a>:'No License'
                                        }else{
                                            value=device[detail.name]
                                        }
                                    return <div className={'flex font-Poppins mb-1'}>
                                    <div className={'font-normal text-sm text-gray-400 w-[45%]'}>{detail.label}:</div>
                                    <div className={'ml-2 font-medium text-gray-800 w-[55%]'}>{value}</div>
                                    </div>
                                })
                                }
                                    </>
                                }

                                <>
                                    <div
                                        className={"w-full h-auto py-[1rem] font-semibold text-lg "}>{isEdit?'Edit Device':'Device Details'}
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={device}
                                        onChange={(e) => onChange(e)}
                                        containerClassName={"h-14 w-full "}
                                        inputClassName={Constants.inputClass}
                                        isShown={error}
                                        isError={isError}
                                        />
                                </>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                        <button type="button"
                                onClick={() => onCancel()}
                                className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                            close
                        </button>
                        <button type="button"
                                onClick={() => createDevice()}
                            // disabled={!device['Type'] || !device['SWversion'] || !device['LicenseValidityCode'] || !device['SN'] || !device['BLEMACAddress']}
                                disabled={disabled}
                                className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                            {type === 'edit' ? 'Update' : 'Create'}
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddDeviceModal;
