import set from 'set-value'


class utils {

    get(obj, path) {
        // Get the path as an array
        path = this.stringToPath(path);

        if (!path)
            return null

        // Cache the current object
        let current = obj;
        // For each item in the path, dig into the object
        for (let i = 0; i < path.length; i++) {
            // If the item isn't found, return the default (or null)
            if (current === null || this.isUndefined(current) || this.isUndefined(current[path[i]]))
                return '';
            // Otherwise, update the current  value
            current = current[path[i]];
        }
        return current;
    };

    set(object, path, value) {
        set(object, path, value);
    }


    /**
     * Checks if value is empty. Deep-checks arrays and objects
     * Note: isEmpty([]) == true, isEmpty({}) == true, isEmpty([{0:false},"",0]) == true, isEmpty({0:1}) == false
     * @param value
     * @returns {boolean}
     */
    isEmpty = (value) => {

        let is = this.isEmpty;

        let isEmptyObject = function (a) {
            if (typeof a.length === 'undefined') { // it's an Object, not an Array
                let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
                    return !is(a[element]);
                });
                return hasNonempty ? false : isEmptyObject(Object.keys(a));
            }

            return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
                return !is(element); // at least one element should be non-empty
            });
        };

        let isEmptyString = function (s) {
            if (typeof s === 'string')
                return s.length === 0;
        };

        return (
            // value === false
            // ||
            typeof value === 'undefined'
            || value == null
            || (typeof value === 'object' && isEmptyObject(value))
            || (typeof value === 'string' && isEmptyString(value))
        );
    };

    isObject = (obj) => {
        return obj && typeof obj === 'object';
    };

    isUndefined = (obj) => {
        return typeof obj === 'undefined';
    };


    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    stringToPath = (path) => {
        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        let output = [];

        // Split to an array with dot notation
        path.split('.').forEach(function (item) {

            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {

                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };

    toNumeric = function (val) {
        return val.replace(/\D/g, '').replace('e', '')
    }



    getDateStringWithYear = function (date) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        let yy = today.getFullYear().toString().substr(2, 2);
        return dd + ' ' + mm + ' ' + yy;
    };


    getTodayDateStringWithYear = function (day, month, year) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = new Date();
        let dd = day ? day : String(today.getDate()).padStart(2, '0');
        let mm = month ? monthNames[month - 1] : monthNames[today.getMonth()]
        let yyyy = year ? year : today.getUTCFullYear()();
        return dd + ' ' + mm + ' ' + yyyy;
    };



    time_convert(num) {
        if (num) {
            let sign = num / 60 < 0 ? "-" : "";
            let min = Math.floor(Math.abs(num / 60))
            let sec = Math.floor((Math.abs(num / 60) * 60) % 60);
            return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
        } else {
            return ""
        }
    }

    yearsDiff(d1, d2) {
        let date1 = new Date(d1);
        let date2 = new Date(d2);
        return date2.getFullYear() - date1.getFullYear();
    }

    BMI_Calculator(height, weight) {
        return weight / (Math.pow(height, 2));

    }

    // returnGender(num) {
    //     let patientGender;
    //     if (patientGender === 1) {
    //         patientGender = 'Male'
    //     } else if (patientGender === 2) {
    //         patientGender = 'Female'
    //     } else if (patientGender === 3) {
    //         patientGender = 'Other'
    //     }
    //     return patientGender
    // }

    getCurrentPageTitle(key) {
        let page
        switch (key) {
            case "page_1":
                page= "Organization Details"
                break;
            case "page_2":
                page= "Admin Person"
                break;
            case "page_3":
                page= "Contact Person"
                break;
            case "page_4":
                page= "Organization Logo"
                break;
            default:
                page= ""
        }
        return page
    }

    /**
     * return the current page of the form according the key
     * @param key
     * @returns {string}
     */
    getCurrentPage(key) {
        let page
        switch (key) {
            case "page_1":
                page = "organization"
                break;
            case "page_2":
                page = "admin"
                break;
            case "page_3":
                page = "contactPerson"
                break;
            case "page_4":
                page = "OrganizationLogo"
                break;
            default:
                page = ""
        }
        return page
    }

    /**
     * check if mail is valid
     * @param email
     * @returns {boolean}
     */
    mailValidation(email) {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    }

    /**
     * check if password is in correct format password must contain 8-20 characters with at least one uppercase, one lowercase, one numeric character, and one symbol
     * @param pass
     * @returns {*}
     */
    passwordValidation(pass) {
        let passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[@$!%*?&])(?=.*[A-Z]).{8,20}$/;
        return pass.match(passwordPattern)
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    /**
     * navigate to correct page according to the role
     * @param role
     */
    navigateAccordingToRole(role) {
        let path
        switch (role) {
            case 'SanollaAdmin':
                path = '/sanollaAdmin/Organizations'
                break;
            case 'OrganizationAdmin':
                path = '/admin/Clinicians'
                break;
            case 'Technician':
                path = '/technician/Devices'
                break;
            case 'Physician':
            case 'Nurse':
                path = '/clinician/Patients'
                break;
            default:
                path = '/login'
        }
        return path
    }
}

export default new utils();
