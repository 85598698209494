import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import OrganizationAdminApi from "../../services/ApiServices/OrganizationAdminApi";
import Constants from "../../utils/constants"
import { object, string} from "yup";



const AddClinicianModal = ({onCancel, onSubmit,isEdit,entry}) => {

    const [clinician, setClinician] = useState({})
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [title, setTitle] = useState('Add Clinician')
    const [formType, setFormType] = useState(formData['add_Clinician'])
    const [count, setCount] = useState(true)
    const [disabled, setDisabled] = useState(true)
    const phoneRegExp = Constants.phoneRegExp

    const userSchema = object().shape({
        FirstName: string().required().typeError('Please insert First Name').default(""),
        LastName: string().required().typeError('Please insert Last Name').default(""),
        Email: string().required().typeError('Please insert Email').email().default(""),
        PhoneNumber: string().required().typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
        Role: string().required().typeError('Please insert Role').default("")
    });
    useEffect(() => {
        setCount(!count)
    },[clinician,error])
    useEffect( () => {
        if(isEdit){
            setClinician(entry)
            setTitle('Edit Clinician')
        }
    }, [entry])

    const onChange = async (e) => {
        let _clinician = {...clinician}
        let name = e.target.name
        let value = e.target.value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        _clinician[name] = value
        await userSchema.validate(_clinician,{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                }
            });
        setClinician(_clinician)
        setFormType(form)
        setError(formErrors)
    }
    const CreateClinician = async () => {
        let clinicianToSave = {...clinician}
        clinicianToSave['Role'] = [clinician['Role']]
        let submit
        if(isEdit){
            submit =await OrganizationAdminApi.editClinician(clinicianToSave,clinician['UserStorageId'])
        }else{
            submit =await OrganizationAdminApi.createClinician(clinicianToSave)
        }
        if(submit && submit.data){
            onSubmit(submit.data)
        }
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:top-0 md:left-0 md:right-0 h-modal md:h-modal bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-full max-w-xl md:h-modal self-center top-0 md:top-16">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 top-6">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">

                                Carefully fill out the clinician's information and
                                click "Create"
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[33.125rem] max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-[32.5rem] w-4/5 justify-self-center rounded-2xl addOrganizationModal`}>

                            <div className={"w-full"}>
                                <>
                                    <div
                                        className={"w-full h-auto py-6 font-semibold text-lg"}>Clinician Details
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={clinician}
                                        onChange={(e) => onChange(e)}
                                        containerClassName={"h-14 w-full "}
                                        inputClassName={Constants.inputClass}
                                        isShown={error}
                                        isError={isError}
                                    />

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() =>CreateClinician()}
                                    //disabled={!clinician['FirstName'] || !clinician['LastName'] || !clinician['Email'] || !clinician['Role'] || !clinician['PhoneNumber'] ||  clinician['PhoneNumber']?.length<10 || (clinician['Email'] && !utils.mailValidation(clinician['Email']  ))}
                                    disabled={disabled}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {isEdit?'Edit':'Create'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddClinicianModal;
