let  Constants = {

    // emailRegExp: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneRegExp:/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    passwordPattern:/^(?=.*\d)(?=.*[a-z])(?=.*[@$!%*?&])(?=.*[A-Z]).{8,20}$/,
    inputClass:"bg-white rounded-xl h-14 w-full pl-1.5 pt-2 h-full bg-transparent border border-[#A6C3F3] focus:border-1 focus:outline-[#3072E3] focus:bg-transparent font-Poppins",
    mailMissingError:"Please enter email address",
    mailNotValidError:"Please enter valid email address"
}

export default Constants

