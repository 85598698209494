import React from "react";
import {TextSanolla} from "./TextSanolla";

export function InputNumberSanolla(props) {

    return (
        <div className={props.containerClassName}>

            <input type={"number"}
                   disabled={props.disabled}
                   name={props.name}
                   className={props.inputClassName}
                   placeholder={props.placeholder}
                   onChange={props.onChange}
                   value={props.value}
                   max={props.max}
                   min={props.min}
            />
            <TextSanolla text={props.label} className={props.labelClassName}/>

        </div>
    )
}
