import React from "react";
import {TextSanolla} from "./TextSanolla";

export function InputSanolla(props) {

    return (
        <div className={props.containerClassName}>
            <input type={"text"} disabled={props.disabled} name={props.name} maxLength={props.maxLength} className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>
            <TextSanolla text={props.label} className={props.labelClassName}/>
        </div>
    )
}
