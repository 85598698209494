import React, {useEffect, useState} from "react";
import './App.css';
import { Route, Routes, useNavigate} from "react-router-dom";
import BG from "./assets/BG.png"
import {Login} from "./components/view/auth/Login";
import {Patients} from "./components/view/physician/Patients";
import {Patient} from "./components/view/physician/patient/Patient";
import {authenticationService} from "./services/AuthinticationService";
import {NavBarSanolla} from "./components/navigation/NavBarSanolla";
import {NavBarSanollaClinicians} from "./components/navigation/NavBarSanollaClinicians";
import {Organizations} from "./components/view/sanolla-admin/Organizations";
import {Devices} from "./components/view/sanolla-admin/Devices";
import {NotFoundPage} from "./components/view/Error/NotFound";
import {VerificationCode} from "./components/view/auth/VerificationCode";
import {OrganizationCard} from "./components/view/sanolla-admin/organization/OrganizationCard";
import {Clinicians} from "./components/view/organizationAdmin/Clinicians";
import {NavBarAdmin} from "./components/navigation/NavBarAdmin";
import {DevicesAdmin} from "./components/view/organizationAdmin/Devices";
import Config from "./config/Config";
import LoginAPICalls from "./services/ApiServices/LoginAPICalls";
import {Technicians} from "./components/view/organizationAdmin/Technicians";
import {DevicesTechnician} from "./components/view/technician/Devices";
import {ForgotPassword} from "./components/view/auth/ForgotPassword";
import {NewPassword} from "./components/view/auth/NewPassword";
import {SoftwareVersions} from "./components/view/sanolla-admin/SoftwareVersions";


function MainRouters() {

    const [currentUser, setCurrentUser] = useState(null);
    const [beVersion, setBeVersion] = useState('');
    const navigate = useNavigate();
    const pathname = window.location.pathname


    useEffect( () => {
        async function fetchData() {
            let beVersion = await LoginAPICalls.getVersion()
            if (beVersion) {
                setBeVersion(beVersion.data)
            }
            authenticationService.currentUser.subscribe(
                currentUser => setCurrentUser(currentUser)
            );

            if(window.location.pathname)
            navigate(window.location.pathname)

            // ...
        }
        fetchData().then();

    }, [navigate])
    let userRole;
    if (currentUser && currentUser['role']) {
        userRole = currentUser['role'][0]
    }


    const handleLogout = async () => {
        authenticationService.logout();
        navigate("/login");
    };
    let clinician = userRole==="Physician" || userRole==="Nurse"


    return (
        <div
            className={`main w-full h-full font-Poppins overflow-auto ${pathname !== '/login' && pathname !== '/' && pathname !== '/verificationCode' && pathname !== '/forgotPassword'&& pathname !== '/newPassword'&& pathname !== '/changePassword' ? 'bg-backgroundSanolla' : 'bg-[#EBF2FC]'}`}>
            <div className={"main w-full h-full"} style={{backgroundImage: `url(${BG})`,backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'}}>
                {pathname !== '/login' && pathname !== '/' && pathname !== '/verificationCode' && pathname !== '/forgotPassword'&& pathname !== '/newPassword'&& pathname !== '/changePassword' ?
                    userRole && userRole === 'SanollaAdmin' ?
                        <NavBarSanolla
                            logoutUser={() => handleLogout()}/> :
                        userRole && userRole === 'OrganizationAdmin' ?
                            <NavBarAdmin
                                logoutUser={() => handleLogout()}
                            />
                            : <NavBarSanollaClinicians
                                logoutUser={() => handleLogout()}
                            /> : null
                }
                <Routes>
                    {userRole==="OrganizationAdmin" && <Route  path="/admin/Clinicians" element={<Clinicians/>}/>}
                    {userRole==="OrganizationAdmin" && <Route  path="/admin/Technicians" element={<Technicians/>}/>}
                    {userRole==="OrganizationAdmin" && <Route  path="/admin/Devices" element={<DevicesAdmin/>}/>}
                    {userRole==="Technician" && <Route  path="/technician/Devices" element={<DevicesTechnician/>}/>}
                    {clinician  && <Route  path="/clinician/Patients" element={<Patients/>}/>}
                    {clinician  && <Route  path="/clinician/Patient/:id" element={<Patient/>}/>}
                    {userRole==="SanollaAdmin" && <Route  path="/sanollaAdmin/Organizations" element={<Organizations/>}/>}
                    {userRole==="SanollaAdmin" && <Route  path="/sanollaAdmin/Organization/:id" element={<OrganizationCard/>}/>}
                    {userRole==="SanollaAdmin" && <Route  path="/sanollaAdmin/Devices" element={<Devices/>}/>}
                    {userRole==="SanollaAdmin" && <Route  path="/sanollaAdmin/SWVersions" element={<SoftwareVersions/>}/>}
                    <Route  path="/verificationCode" element={<VerificationCode/>}/>
                    <Route  path="/forgotPassword" element={<ForgotPassword/>}/>
                    {/*<Route  path="/changePassword" element={<FirstConnectNewPassword/>}/>*/}
                    <Route  path="/newPassword" element={<NewPassword/>}/>
                    <Route  path="/" element={<Login/>}/>
                    <Route  path="/login" element={<Login/>}/>
                    <Route  path="*" exact={true} element={<NotFoundPage/>}/>
                </Routes>
            </div>
            <label className={'absolute bottom-8 left-8 text-slate-400'}>{Config.version} ({Config.BuildVersion})
                be-V:{beVersion}</label>

        </div>
    );
}

export default MainRouters;
