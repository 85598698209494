import React from "react";
import {TextSanolla} from "./TextSanolla";

export function EmailInputSanolla(props) {

    return (
        <div className={props.containerClassName}>

            <input type={"email"} disabled={props.disabled} maxLength={props.maxLength} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name={props.name} className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>
            <TextSanolla text={props.label} className={props.labelClassName}/>
        </div>
    )
}
