import React, {useState, useCallback, useEffect} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import SVGraphics from "../../../assets/SVGraphics";
import AddTechnicianModal from "../../modals/AddTechnicianModal";
import OrganizationAdminApi from "../../../services/ApiServices/OrganizationAdminApi";
import {makeWarning} from "../../modals/WarningModal";


export function Technicians() {
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [technicians, setTechnicians] = useState([]);
    const [technician, setTechnician] = useState(null);
    const [closeModal, setCloseModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        fetchData().then(p=>{
            if(p&&p.data){
                setTechnicians(p.data)
                setFilteredData(p.data)
            }
        })
        setIsLoading(false)
    }, [])
    const fetchData= async()=> {
        return await OrganizationAdminApi.getOrganizationTechnicians()
    }
    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        setIsEdit(false)
        setTechnician(null)
    }, []);
    const handleSubmit = () => {
        fetchData().then(p=>{
            if(p&&p.data){
                setTechnicians(p.data)
                setFilteredData(p.data)
            }
        })
        handleCloseModal()
        setCloseModal(false)
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'PII.FirstName', // accessor is the "key" in the data
            sortType: 'string'
        },
        {
            Header: 'Last Name',
            accessor: 'PII.LastName',
            sortType: 'string'
        },
        {
            Header: 'Phone',
            accessor: 'PII.PhoneNumber',
        },
        {
            Header: 'Email',
            accessor: 'PII.Email',
        },
        {
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            closeModal={closeModal}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'} onClick={()=>openEditTechnician(cell.row.original)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                <div className={'flex justify-between h-1/2 cursor-pointer'} onClick={()=>removeTechnician(cell.row.original)}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    const removeTechnician = async (id) => {
        setCloseModal(true)
        let remove
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this Technician?",message:"This Technician will be hidden immediately, you can’t undo this action."});
        if(message){
            remove = await OrganizationAdminApi.deleteTechnician(id.UserStorageId)
            if(remove){
                fetchData().then(p=>{
                    if(p&&p.data){
                        setTechnicians(p.data)
                        setFilteredData(p.data)
                    }
                })
            }
        }
        setCloseModal(false)
    }
    const openEditTechnician = (data) => {
        setCloseModal(true)
        setTechnician(data)
        setIsEdit(true)
        setShowModal(true)

    }
    // const onRowClick = (id) => {
    //     id=id.original.OrganizationID
    //     navigate('/admin/Organization',{state:id});
    // }
    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < technicians.length; i++) {
            filteredData = technicians.filter(user =>
                String(user['PII']['FirstName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['PII']['LastName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['PII']['PhoneNumber']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['PII']['Email']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Technicians List"} className={"font-medium text-titleColor text-xl  "}/>
                 <ButtonSanolla onClick={handleShowModal} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'+ Add Technician'}/>
            </span>
            {showModal &&
            <AddTechnicianModal onCancel={handleCloseModal}
                                  onSubmit={handleSubmit}
                                  isEdit={isEdit}
                                  entry={technician}
            />}
            <div className={"w-full h-[720px] overflow-auto"}>
            <Table data={filteredData?filteredData:[]} columns={columns}
                   isLoading={isLoading}
                   // onClick={(id)=>onRowClick(id)}
            />
            </div>
        </div>
    )
}
