const Config = {

    globalUrl: process.env.REACT_APP_API_ENDPOINT,
    userRoles: ['Admin', 'Clinician', 'Technician'],
    loginRoles: ['Admin', 'Clinician', 'Technician'],

    roles: {
        Clinician: 'Clinician',
        Admin: 'Admin',
        Technician: 'Technician',

    },
    version: 'V' + require('../../package.json')['version'],
    BuildVersion: 'V' + require('../../package.json')['BuildVersion'],
};

Object.freeze(Config);
export default Config;
