import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const globalUrl = process.env.REACT_APP_API_ENDPOINT

const OrganizationAdminApi = {

    /*************** Clinicians *****************************************/
    getAllClinicians: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/clinicians/${organizationAdmin.profile.UserStorageId}/getAllClinicians`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

    createClinician: async (clinician) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/createClinician`,
                clinician,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deleteClinician: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.delete(
                `${globalUrl}/api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/${id}/deleteClinician`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    editClinician: async (clinician,clinicianId) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/${clinicianId}/editClinician`,
                clinician,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    /*************** Technician *****************************************/
    getOrganizationTechnicians : async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/organizations/${organizationAdmin.profile.UserStorageId}/getOrganizationTechnicians `,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    createTechnician: async (body) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/createTechnician`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    editTechnician: async (body,id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.post(
                `${globalUrl}api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/${id}/editTechnician`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deleteTechnician: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let organizationAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': organizationAdmin.token,
                },
            };
            return await restApi.delete(
                `${globalUrl}/api/v1/webapp/users/${organizationAdmin.profile.UserStorageId}/${id}/deleteTechnician`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

};

export default OrganizationAdminApi
