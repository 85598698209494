import {BehaviorSubject} from 'rxjs';
import {LoginAPICalls} from "./ApiService";
import utils from "../utils/utils";

const currentUserSubject = new BehaviorSubject();
const mfaUserSubject = new BehaviorSubject();
const pwdUserSubject = new BehaviorSubject();

let userLoggedIn = false;
let mfaUserLoggedIn = false
let pwdUserLoggedIn = false;

export const authenticationService = {
    login,
    logout,
    isLoggedin,
    reauthenticate,
    submit2fa,
    setUserData,
    changeNewPassword,
    resetPassword,
    currentSessionToken,
    userLoggedIn,
    mfaUserLoggedIn,
    pwdUserLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentMfaUser: mfaUserSubject.asObservable(),
    get currentMfaUserValue() {
        return mfaUserSubject.value
    },
    pwdUser: pwdUserSubject.asObservable(),
    get pwdUserValue() {
        return pwdUserSubject.value
    }
};

async function login(username, password,reCaptchaToken) {

    let response = await LoginAPICalls.signIn(username, password,reCaptchaToken);
    if (response && response.status < 400) {
        let data = response.data
        currentUserSubject.next(data);
        sessionStorage.setItem('loggedInUser', JSON.stringify(data))
        sessionStorage.setItem('userLoggedIn', String(true))
        // utils.set(data,'status','pass')
        // utils.set(data,'updatePassword',true)
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa') {
            mfaUserLoggedIn = true;
            mfaUserSubject.next(data);
        } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'PASS') {
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true) {
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else  {
                userLoggedIn = true;
                currentUserSubject.next(data);

            }
        }
    }

    return response;
}
async function refreshToken(refreshToken) {
    return new Promise(async (resolve, reject) => {
        let user = JSON.parse(JSON.stringify(currentUserSubject['value']));
        if (!refreshToken) {
            refreshToken = user['refreshToken']
        }
        await LoginAPICalls.refreshToken(refreshToken).then(async token => {
            resolve(token['accessToken'])
        }).catch(e => {
            reject(e);
        })
    })
}
async function setUserData(vals) {
    currentUserSubject.next(vals);
    sessionStorage.setItem('loggedInUser', JSON.stringify(vals))
}
async function currentSessionToken() {
    let user = JSON.parse(JSON.stringify(currentUserSubject['value']));
    let currentToken = user['token']
    let refreshToken = user['refreshToken']
    let decodedToken = utils.parseJwt(currentToken)
    // let exp = (decodedToken['exp']) * 1000
    // let curTime = (new Date()).getTime()
    // let tokenExpired = curTime - exp > 0
    // return new Promise(async (resolve, reject) => {
    //     if (tokenExpired) {
    //         await refreshToken(refreshToken).then(async token => {
    //             //set new token to user
    //             user['token'] = token
    //             await currentUserSubject.next(user);
    //             sessionStorage.setItem('loggedInUser', JSON.stringify(user))
    //             resolve(token)
    //         }).catch(e => {
    //             console.error(e);
    //             reject(e);
    //         })
    //     } else {
    //         resolve(currentToken)
    //     }
    //
    // });
    return currentToken
}

async function submit2fa(mfaCode) {
    let response = await LoginAPICalls.submit2fa(mfaCode);
    if (response.status < 400) {
        let data = response.data
        userLoggedIn = true;
        currentUserSubject.next(data);
        sessionStorage.setItem('loggedInUser', JSON.stringify(data))
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true) {
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false) {
                userLoggedIn = true;
                currentUserSubject.next(data);
            }
            mfaUserSubject.next(null);
            mfaUserLoggedIn = false
        }
    }
    return response;
}
function reauthenticate() {
    let data = JSON.parse(sessionStorage.getItem('loggedInUser'))
    sessionStorage.setItem('userLoggedIn', String(true))
    currentUserSubject.next(data);
}
function isLoggedin() {
    return Boolean(sessionStorage.getItem('userLoggedIn') === "true" && sessionStorage.getItem('loggedInUser') !== "undefined")
}

async function changeNewPassword(password) {
    let response = await LoginAPICalls.changeNewPassword(password);
    if (response && response.hasOwnProperty('data') &&
        response.data.hasOwnProperty('newPassword') &&
        response.data['newPassword'] === true) {
        let val = Object.assign({}, pwdUserSubject.value)
        pwdUserSubject.next(null);
        pwdUserLoggedIn = false
        currentUserSubject.next(val);
        userLoggedIn = true;
    }
    return response;
}

async function resetPassword(username, reCaptchaToken) {
    return await LoginAPICalls.resetPassword(username, reCaptchaToken);
}

function logout() {
    document.getElementById('root').click();
    userLoggedIn = false;
    currentUserSubject.next(null);
    sessionStorage.clear()
}
