import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {logDOM} from "@testing-library/react";
class ReCaptcha extends Component {

    render() {
        let {action,refReCaptcha, setErr} = this.props
        return <ReCAPTCHA
            ref={refReCaptcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size={'invisible'}
            explicit={false}
            onChange={async reCaptchaToken => {
                console.log("reCaptchaToken",reCaptchaToken)
                if (!!reCaptchaToken) {
                    await action(reCaptchaToken)
                }
                else {
                    setErr('Blocked By ReCAPTCHA')
                }
            }}
            onVerify={async reCaptchaToken => {
                if (!!reCaptchaToken) {
                    await action(reCaptchaToken)
                }
                else {
                    setErr('Blocked By ReCAPTCHA')
                }
            }}
            onExpired={() => {setErr(
                'ReCAPTCHA Expired, please refresh the page'
            )}}
            onErrored={(err) => {
                setErr('ReCAPTCHA Error');
                console.error(err)
            }}
        />;
    }
}
export default ReCaptcha;
