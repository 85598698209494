import React, {useState, useEffect} from "react";
import {Table} from "../../table/table";
import {SearchInput} from "../../input/SearchInput";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import SVGraphics from "../../../assets/SVGraphics";
import DeviceApi from "../../../services/ApiServices/DeviceApi";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {TextSanolla} from "../../input/TextSanolla";
import OrganizationAssignDeviceModal from "../../modals/OrganizationAssignDeviceModal";


export function DevicesAdmin() {

    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [isAssignDevice, setIsAssignDevice] = useState(false);

    useEffect( () => {
        fetchData().then(p=>{
            if(p && p.data){
                setDevices(p.data)
                setFilteredData(p.data)
                setIsLoading(false)
            }
        })
    }, [])
    const fetchData= async()=> {
        return await DeviceApi.getOrganizationDevices()
    }


    const columns = [
        {
            Header: 'Type',
            accessor: 'Type', // accessor is the "key" in the data
        },
        {
            Header: 'Device Version',
            accessor: 'SWVersion',
        },
        {
            Header: 'Version Status',
            accessor: 'DeviceVersionStatus',

        },
        // {
        //     Header: 'SW Version',
        //     accessor: 'SWVersion',
        // },
        {
            Header: 'License Code',
            accessor: 'LicenseValidityCode',
        },
        {
            Header: 'S/N',
            accessor: 'SN',
        },
        {
            Header: 'BLE MAC',
            accessor: 'BLEMACAddress',
        },
        // {
        //     Header: 'Device version',
        //     accessor: 'DeviceVersion',
        //
        // },

        {
            Header: 'Warranty End',
            accessor: 'WarrantyExpiration',
        },
        // {
        //     accessor: "[editButton]",
        //     maxWidth: "30px",
        //     width: "30px",
        //     minWidth: "30px",
        //     disableSortBy: true,
        //     Cell: ({ cell }) => {
        //         return (
        //             <div className={"flex justify-end"}>
        //                 <ActionsMorePopover
        //                     class={'h-20'}
        //                     cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
        //                         {/*<div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer p-1'} onClick={()=>openEditDevice(cell.row)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Update</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`update`}/></div>*/}
        //                         <div className={'flex justify-between h-1/2 cursor-pointer'}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>
        //                     </div>}
        //                 />
        //             </div>
        //         )
        //     }
        // },
    ]
    // const openEditDevice = (data) => {
    //     // setIsEdit(true)
    //     // setDevice(data)
    //     // setShowModal(true)
    // }
    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < devices.length; i++) {
            filteredData = devices.filter(device =>
                String(device['BLEMACAddress']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['DeviceVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SN']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['Type']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SWVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            )
        }
        setFilteredData(filteredData)
    }
    const openAssignDevice = async () => {
        let device = await DeviceApi.getAllDevices()
        if(device && device.data){
            let _devices = []
            device.data.forEach(item=>{
                if(!item.OrganizationID){
                    _devices.push(item.BLEMACAddress)
                }
            })
            setEditData(_devices)

        }
        setIsAssignDevice(true)
        // setIsEdit(true)



        // setShowModal(true)
    }
    const handleAssignDevice = () => {
        fetchData().then(p=>{
            setDevices(p.data)
            setFilteredData(p.data)
            setIsAssignDevice(false)
        })
    }
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Devices List"} className={"font-medium text-titleColor text-xl  "}/>
                 <ButtonSanolla onClick={openAssignDevice} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'Assign Device'}/>
            </span>

            {isAssignDevice &&
                <OrganizationAssignDeviceModal
                    data={editData}
                    onCancel={()=>setIsAssignDevice(false)}
                    onSubmit={handleAssignDevice}

                />
            }

            <div className={"w-full h-[680px] overflow-hidden"}>
                    <Table data={filteredData?filteredData:[]} isLoading={isLoading} columns={columns} headerClassName={"text-[0.875rem]"}/>

            </div>
        </div>
    )
}
