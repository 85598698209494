import React from "react";
import './App.css';

import MainRouters from "./MainRouters";



const App = () => (
    <MainRouters/>
)

export default App;

// test