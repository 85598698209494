import React, {useCallback, useEffect, useState} from "react";
import { useNavigate,} from 'react-router-dom';
import {PatientCard} from "./PatientCard";
import {NavBar} from "../../../navigation/NavBar";
import {BodyScans} from "./BodyScans";
import {PatientsNotes} from "./PatientsNotes";
import AddPatientModal from "../../../modals/AddPatientModal";
import CliniciansApi from "../../../../services/ApiServices/CliniciansApi";
import moment from "moment";
import _ from "lodash";
import {ButtonSanolla} from "../../../input/ButtonSanolla";
import {LoadingComponent} from "../../../input/LoadingComponent";
import {makeWarning} from "../../../modals/WarningModal";
import { useParams  } from "react-router-dom";


export function Patient() {
    const [navType, setNavType] = useState('bodyScans');
    const [patient, setPatient] = useState({});
    const [patientData, setPatientData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const [bodyScans, setBodyScans] = useState([]);
    const [examNum, setExamNum] = useState(null);
    const [notesSum, setNotesSum] = useState(0);
    const [isAddNote, setIsAddNote] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [notes, setNotes] = useState([]);
    let params  = useParams();
    const navigate = useNavigate();
    const navigateBackToPatients = () => {
        navigate('/clinician/Patients')
    }
    const navListItems =[{name:'bodyScans',label:'Body Examinations',sum:examNum},{name:'patientNotes',label:'Patient Notes',sum:notesSum}]

    useEffect( () => {
        let id = params.id
        let sum=0
        if(id){
            const fetchDataGetPatient= async(id)=> { //get patient data from db
                    let patient = await CliniciansApi.getPatient(id)
                    if(patient && patient.data){
                        let user = patient.data
                        setPatientData(user)
                        setUserData(user)
                    }
            }
            fetchDataGetPatient(id).then()
            fetchData(id).then(p=> { // get all the patient exams from db
                Object.keys(p.data).forEach(function (key, index) {
                    let item = {date: moment(key).format('DD.MM.YYYY'), exams: p.data[key],id:String(index)}
                    scans.push(item)
                });
                scans.forEach(scan => {
                    sum=sum+scan.exams?.length
                    scan.exams.forEach(exam => {
                        exam.Examinations.forEach(Examination => {
                            let points = []
                            let groupPoints = _.mapValues(_.groupBy(Examination.points, 'PointName'),
                                clist => clist.map(point => _.omit(point, 'PointName')));
                            Object.keys(groupPoints).forEach(function (key, index) {
                                let item = {pointName: key, id: String(key+index), Results: groupPoints[key]}
                                points.push(item)
                            })
                            Examination['points'] = points
                        })
                    })
                })
                setBodyScans(scans)
                setExamNum(sum)
            })
            const fetchDataNotes= async()=> {// get all patient notes from db
                let patientNotes = []
                let sum = 0
                let notes = await CliniciansApi.getAllPatientNotes(id)
                if(notes && notes.data){
                    Object.keys(notes.data).forEach(function (key, index) {
                        let item = {date: key, notes: notes.data[key],id:String(index)}
                        patientNotes.push(item)
                        sum = sum + notes.data[key].length
                    });
                    setNotes(patientNotes)
                    setNotesSum(sum)
                }
            }
            fetchDataNotes().then()
        }
        let scans = []
        setIsLoading(false)
    }, [params.id])

    const fetchData= async(id)=> {
        if(id){
            return await CliniciansApi.getPatientExams(id)
        }
    }
    const setUserData = (data) =>{ // save the patient data  in the right order to display
        let user ={}
        if(data && data.PII){
            user ={ fullName:data.PII.FirstName+' '+data.PII.LastName,
                idNumber:data.PatientID,
                age:data.PII.Age,
                phone:data.PII.PhoneNumber,
                email:data.PII.Email}
        }
        setPatient(user)
        setUserId(data.UserStorageId)
    }

    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleEditPatient = useCallback((editPatient) => {
        if(editPatient){
            setUserData(editPatient)
            setPatientData(editPatient)
            setShowModal(false);
        }
    }, []);

    const hidePatient =async  () => {
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this Patient?",message:"This Patient will be hidden immediately, you can’t undo this action."});
        if(message) {
            let deletePatient = await CliniciansApi.deletePatient(userId)
            if (deletePatient) {
                navigate('/clinician/Patients');
            }
        }
    }

    return (
        <div className="relative sm:rounded-lg w-tableWidth m-auto mt-10 h-full font-Poppins">
            <div onClick={() => navigateBackToPatients()} className={"cursor-pointer text-titleColor text-base"}> &#10094; &nbsp; Back</div>
            <span className={'flex w-full justify-between items-center mt-5'}>
                {/*<TextSanolla text={"Patient"} className={"font-medium text-titleColor text-xl  "}/>*/}
            </span>
            <PatientCard patient={patient}
                         editPatient={()=>handleShowModal()}
                         hidePatient={()=>hidePatient()}
            />
            <NavBar list={
                <ul className="flex w-full pt-0 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-[0.875rem] md:font-medium md:border-0 md:dark:bg-gray-900 h-10">
                {navListItems?.map(item => {
                    return <li key={item.name} onClick={() => {setNavType(item.name)
                        setIsAddNote(false)
                    }
                    } className={"w-1/5"}>
                        <div className={'flex'}>
                            <div
                                className={`block pr-4 ${navType === item.name ? "text-blue-700 " : "text-gray-700"} md:bg-transparent  md:p-0 cursor-pointer flex`}
                                aria-current="page">{item.label}</div>
                            <div
                                className={'ml-2 mt-2 rounded-full bg-[#EBF2FC] h-6 w-6 flex flex-col justify-center'}>{item.sum}</div>
                        </div>
                        <div
                            className={`${navType === item.name ? "border-b-2 border-blue-700" : "border-b-1 border-gray-700"} mt-2`}/>
                    </li>
                })
                }
                    {navType==='patientNotes' &&
                    <li className={"flex justify-end w-4/5 "} >
                        <ButtonSanolla onClick={()=>setIsAddNote(true)} containerClassName={'bg-sanollaButtonColor w-40 h-10 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor self-center'} label={'+ Add Note'}/>
                    </li>
                    }

            </ul>}/>

            {navType==='bodyScans' && userId?
                <BodyScans
                userId={userId}
                bodyScans={bodyScans}
                />
                :navType==='patientNotes' && userId?
                <PatientsNotes userId={userId}
                notes={notes}
                isAddNote={isAddNote}
                closeModal={()=>setIsAddNote(false)}
                />:null
            }
            {showModal &&
            <AddPatientModal onCancel={handleCloseModal}
                             onSubmit={(editPatient)=>handleEditPatient(editPatient)}
                             isEdit={true}
                             entry={patientData}
            />}

            {isLoading &&
            <LoadingComponent/>
            }
        </div>
    )
}
