import React, {useState, useEffect} from "react";
import DeviceApi from "../../services/ApiServices/DeviceApi";
import {SelectSanolla} from "../input/SelectSanolla";



const AssignDeviceModal = ({onCancel, onSubmit,entry, data,type}) => {

    const [device, setDevice] = useState({})
    const [options, setOptions] = useState([])
    const [label, setLabel] = useState('Assign To')
    const [name, setName] = useState('OrganizationID')
    const [title, setTitle] = useState('Assign Device')


    useEffect( () => {
        let _device = {}
        setOptions(data)
        if(type==='assign'){
            _device={
                BLEMACAddress:entry['BLEMACAddress'],
                OrganizationID:entry['OrganizationID']
            }
            setTitle('Assign Device')
            setLabel('Assign To')
            setName('OrganizationID')
        }else{
            setTitle('Push SW update')
            setLabel('SW Version*')
            setName('SW')
        }
        setDevice(_device)
     // let modalType =
    }, [type,data,entry])


    const updateDevice = async () => {
        let submit
        if(type==='assign'){
            submit =await DeviceApi.assignDevice(device)
            if(submit && submit.data){
                onSubmit(submit.data.patient)
            }
        }else{
            submit =await DeviceApi.addDevice(device)
            if(submit && submit.data){
                onSubmit()
            }
        }

    }
    const onChange = (e) => {
        let newDevice = {...device}
        let name = e.target.name
        let value = e.target.value
        if (value === "") {
            value = null
        }
        newDevice[name] = value
        setDevice(newDevice)
    }

    let isShown=false
    if(data['OrganizationName'] && data['OrganizationName']!== ""){
        isShown=true
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-auto max-w-xl md:h-auto self-center">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 ">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                {`Here you can ${type==='assign'?'assign device to organization':'Push SW update'}`}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[27rem] max-h-full w-[30rem] flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div className={`mx-auto h-[27rem w-[26rem] justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full pb-8"}>

                                <>
                                    <div
                                        className={"w-full h-auto py-6 font-semibold text-lg"}>Device Details
                                    </div>
                                    <SelectSanolla
                                    label={label}
                                    name={name}
                                    placeholder={label}
                                    data={options}
                                    value={device[name]}
                                    onChange={(e) => onChange(e)}
                                    containerClassName={"h-14 w-full "}
                                    labelClassName={`${isShown?'inline':'hidden'} text-{#657690} relative text-xs pl-1.5 pt-1 bottom-14`}
                                    inputClassName={"bg-white rounded-xl h-14 w-full pl-1.5 pt-2 h-full bg-transparent border border-[#A6C3F3] focus:border-1 focus:outline-[#3072E3] focus:bg-transparent font-Poppins"}
                                    />

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() => updateDevice()}
                                    disabled={type==='assign'?!device['BLEMACAddress']:false}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {'Update'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AssignDeviceModal;
