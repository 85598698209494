import React from "react";
import {Table} from "../../table/table";

export function TechnicianTable(props) {


    const columns = [
        {
            accessor: 'FirstName',
            Header: 'First Name', // accessor is the "key" in the data
            sortType: 'string'
        },
        {
            accessor: 'LastName',
            Header: 'Last Name',
            sortType: 'string'
        },
        {
            accessor: 'Phone',
            Header: 'Phone',
        },
        {
            accessor: 'Email',
            Header: 'Email',
        },
    ]

    return (
            <div className={"w-full h-full overflow-auto"}>
                    <Table data={props.data} columns={columns} isLoading={props.isLoading} headerClassName={"text-[0.875rem]"}/>
            </div>
    )
}
