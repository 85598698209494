import React, {useState, useCallback, useEffect} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import AddOrganizationModal from "../../modals/AddOrganizationModal";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import SuperAdminApi from "../../../services/ApiServices/SuperAdminApi";
import SVGraphics from "../../../assets/SVGraphics";
import {useNavigate} from "react-router-dom";
import EditOrganizationModal from "../../modals/EditOrganizationModal";



export function Organizations() {
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect( () => {
        fetchData().then(p=>{
            setOrganizations(p.data)
            setFilteredData(p.data)
            setIsLoading(false)
        })
    }, [])
    const fetchData= async()=> {
        return await SuperAdminApi.getAllOrganizations()
    }
    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
        setOrganization(null)
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);
    const handleCloseEditModal = useCallback(() => {
        setIsEdit(false);
    }, []);
    const handleSaveOrganization = () =>{
        fetchData().then(p=>{
            setOrganizations(p.data)
            setFilteredData(p.data)
        })
        handleCloseModal()
    }
    const handleEditOrganization = () =>{
        fetchData().then(p=>{
            setOrganizations(p.data)
            setFilteredData(p.data)
            setIsLoading(false)
            handleCloseEditModal()
        })
    }

    const columns = [
        {
            Header: 'Name',
            accessor: 'Name', // accessor is the "key" in the data
            sortType: 'string'
        },
        {
            Header: 'Address',
            accessor: 'Address'
        },
        {
            Header: 'Users',
            accessor: 'usersCount',
        },
        {
            Header: 'Devices',
            accessor: 'devicesCount',
        },
        {
            Header: 'Contact',
            accessor: 'ContactPersonName'
        },
        {
            Header: 'Type',
            accessor: 'OrganizationType',
        },
        {
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            class={'h-20'}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                <div className={'flex justify-between h-full cursor-pointer'} onClick={()=>editOrganization(cell.row.original)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"} >Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                {/*<div className={'flex justify-between h-1/2 cursor-pointer'}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>*/}
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    const editOrganization = (org) =>{
        setOrganization(org)
        setIsEdit(true)

    }
    const onRowClick = (id) => {
        id=id.original.OrganizationID
        navigate(`/sanollaAdmin/Organization/${id}`);
    }
    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < organizations.length; i++) {
            filteredData = organizations.filter(user =>
                String(user['Name']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Address']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['ContactPersonName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['OrganizationType']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Organizations List"} className={"font-medium text-titleColor text-xl  "}/>
                 <ButtonSanolla onClick={handleShowModal} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'+ Add Organization'}/>
            </span>
            {showModal &&
            <AddOrganizationModal onCancel={handleCloseModal}
                                  onSubmit={handleSaveOrganization}
                                  entry={organization}
            />}
            {isEdit &&
            <EditOrganizationModal onCancel={handleCloseEditModal}
                                  onSubmit={handleEditOrganization}
                                  isEdit={isEdit}
                                  entry={organization}
            />}

            <div className={"w-full h-[680px] overflow-auto"}>
            <Table data={filteredData?filteredData:[]} columns={columns} isLoading={isLoading} onClick={(id)=>onRowClick(id)}/>
            </div>
        </div>
    )
}
