import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import SuperAdminApi from "../../services/ApiServices/SuperAdminApi";
import Constants from "../../utils/constants"
import {object, string} from "yup";



const EditOrganizationModal = ({onCancel, onSubmit,entry}) => {

    const [organization, setOrganization] = useState({})
    const [fileName, setFileName] = useState('Logo')
    const [imageSrc, setImageSrc] = useState(null)
    const [logo, setLogo] = useState(null)
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [formType, setFormType] = useState(formData['edit_organization'])
    const [count, setCount] = useState(true)
    const [disabled, setDisabled] = useState(true)

    const organizationSchema = object().shape({
        Name: string().required().typeError('Please insert Name').default(""),
        OrganizationNumber: string().required('Please insert Number').typeError('Please insert Number').default(""),
        Address: string().optional().nullable().default(""),
        OrganizationType: string().optional().nullable().default(""),
    });
    useEffect(() => {
        setCount(!count)
    },[organization,error])
    useEffect( () => {
        let organizationId = entry.OrganizationID
        const getOrganization = async () => {
            let org = await SuperAdminApi.getOrganization(organizationId)
            if (org && org.data) {
               return org.data
            }
        }
        const fetchData =  async () =>{
            let data =await SuperAdminApi.getOrganizationTypes()
            let typeData=[]
            if(data && data.data){
                data.data.forEach(d=>{
                    typeData.push({value:d,
                        label: d})
                })
            }
            let types=[...formType]
            types[3]["data"]=typeData
            setFormType(types)
        }
        fetchData().then()
        getOrganization().then(org=>{
            setImageSrc(org.logo)
            setOrganization(org)
        })

     // let modalType =
    }, [])

    // const onChange = (e) => {
    //
    //     let newPatient = {...patient}
    //     let name = e.target.name
    //     let value = e.target.value
    //     if (value === "") {
    //         value = null
    //     }
    //     if(name==="Age"){
    //         value=value && value > 120?Number(value.toString().slice(0, value.toString().length - 1)):value
    //     }
    //     newPatient[name] = value
    //     setPatient(newPatient)
    // }
    const updateOrganization = async () => {
        let response = await SuperAdminApi.EditOrganizationInfo(organization,organization.OrganizationID)
        if(response && response.data){
            if(logo){
               await SuperAdminApi.uploadNewOrganizationLogo(logo,organization.OrganizationID)
            }
            onSubmit(response.data['organization'])
        }
    }
    const onChange = async (e) => {

        let newOrganization = {...organization}
        let name = e.target.name
        let value = e.target.value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        if(name==="OrganizationLogo"){
            if (e.target.files && e.target.files[0]) {
                setFileName(e.target.files[0]['name'])
                setImageSrc(URL.createObjectURL(e.target.files[0]));
                setLogo(e.target.files[0])
            }
        }else if(name==="OrganizationNumber"){
            value=value?.replace(/[^\d]+/g,'')
        }

        newOrganization[name] = value
        await organizationSchema.validate(newOrganization,{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                }
            });

        setOrganization(newOrganization)
        setFormType(form)
        setError(formErrors)
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 bottom-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:top-0 md:left-0 md:right-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-2/5 h-full max-w-xl md:h-modal self-center top-0 md:top-16">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 top-0">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                Edit Organization Info
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                To update organization's information, simply fill out the provided form with your updated details
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[34.375rem] max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-full w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full"}>

                                <>
                                    <div
                                        className={"w-full h-auto py-[1.5rem] font-semibold text-lg"}>Organization Details
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={organization}
                                        onChange={(e) => onChange(e)}
                                        fileName={fileName}
                                        containerClassName={"h-14 w-full "}
                                        inputClassName={Constants.inputClass}
                                        isShown={error}
                                        isError={isError}
                                        previewImage={imageSrc}
                                        removeStyle={{display:'none'}}
                                    />

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() => updateOrganization()}
                                    //disabled={(!organization['Name'] || !organization['OrganizationNumber'])}
                                    disabled={disabled}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                Update
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default EditOrganizationModal;
