import React, {useEffect, useRef, useState} from "react";
import SanollaLogo from "../../assets/SanollaLogo.png"
import {authenticationService} from "../../services/AuthinticationService";
import {Overlay, Tooltip} from "react-bootstrap";
import {NavBarLogOutPopUp} from "./NavBarLogOutPopUp";
import AppLogout from "../view/auth/AppLogout ";




export function NavBarSanollaClinicians(props) {
    const {
        logoutUser
    }= props


    const [currentUser, setCurrentUser] = useState(null);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    useEffect(() => {
        authenticationService.currentUser.subscribe(
            currentUser => setCurrentUser(currentUser)
        );
    }, [])

    return (
        <AppLogout>
        <div className="text-4xl font-medium text-center text-gray-500 border-b border-gray-200 max-h-[4rem] bg-white flex items-center justify-center">
            <nav className="flex border-gray-200 px-2 sm:px-4 pt-2.5  items-center  h-[4rem] max-h-[4rem] w-tableWidth" >
                <div className={'flex max-h-full h-1/2 w-full justify-between'}>
                    <img src={SanollaLogo} alt={'Sanolla'} className={""} />
                    <div ref={target} onClick={() => setShow(!show)} className={" cursor-pointer rounded-full w-8 h-8 bg-[#F3F8FF] text-[0.875rem] text-[#588DE9] font-semibold font-Poppins flex flex-col justify-center"}>
                        {currentUser?currentUser['profile']['FirstName']?.charAt(0).toUpperCase():''}
                    </div>
                    <Overlay
                        placement="bottom"
                        target={target.current}
                        show={show}
                        delay={{ show: 250, hide: 400 }}>
                        {(props) =>( <Tooltip id="button-tooltip" {...props}>
                            <NavBarLogOutPopUp
                                logoutUser={() => logoutUser()}
                            />
                        </Tooltip>)}
                    </Overlay>

                </div>
            </nav>
        </div>
        </AppLogout>
    )
}
