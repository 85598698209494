import React, {useState, useEffect, useCallback} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import {SearchInput} from "../../input/SearchInput";
import {useNavigate} from "react-router-dom";
import CliniciansApi from "../../../services/ApiServices/CliniciansApi";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import AddPatientModal from "../../modals/AddPatientModal";
import SVGraphics from "../../../assets/SVGraphics";
import {makeWarning} from "../../modals/WarningModal";



export function Patients() {
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [openEditPatient, setOpenEditPatient] = useState(false);

    useEffect( () => {
        fetchData().then(p=>{
            if(p && p.data){
                setPatients(p.data)
                setIsLoading(false)
            }

        })
    }, [])

    const fetchData= async()=> {
            return await CliniciansApi.getAllPatients()
    }

    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

       const handleCreatePatient = useCallback(() => {
           fetchData().then(p=>{
               setPatients(p.data)
           })
        setShowModal(false);
    }, []);



    const navigate = useNavigate();
    const columns = [
        {
            Header: 'First Name',
            accessor: 'PII.FirstName', // accessor is the "key" in the data
            sortType: 'string'
        },
        {
            Header: 'Last Name',
            accessor: 'PII.LastName',
            sortType: 'string'
        },
        {
            Header: 'ID Number',
            accessor: 'PatientID',
        },
        {
            Header: 'Age',
            accessor: 'PII.Age',
        },
        {
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            closeModal={closeModal}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'} onClick={()=>editPatient(cell.row.original)}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                <div className={'flex justify-between h-1/2 cursor-pointer'} onClick={()=>deletePatient(cell.row.original.UserStorageId)}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Hide</div> <SVGraphics className={"w-8 self-center mr-4 relative top-2 cursor-pointer"} svgname={`blackEye`}/></div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    const editPatient =(row)=>{
        setPatient(row)
        setOpenEditPatient(true)
    }
    const deletePatient = async(row)=>{
        setCloseModal(true)
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this Patient?",message:"This Patient will be hidden immediately, you can’t undo this action."});
        if(message){
            let deletePatient = await CliniciansApi.deletePatient(row)
            if(deletePatient){
                let newPatients = patients.filter(x=>x.UserStorageId!==row)
                setPatients(newPatients)
            }
        }
        setCloseModal(false)

    }
    const onRowClick = (id) => {
        id=id.original.UserStorageId
        navigate(`/clinician/Patient/${id}`);
    }
    const searchOnChange = async (event) =>{
        let searchPatients = await CliniciansApi.searchPatient(event.target.value)
        if(searchPatients){
            setPatients(searchPatients.data)
        }
    }

    const handleEditPatient = useCallback( () => {
        fetchData().then(p=>{
            setPatients(p.data)
        })
        setOpenEditPatient(false);
    }, []);


    return (
        <div className="overflow-x-auto overflow-y-auto relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(event)=>searchOnChange(event)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Patients List"} className={"font-medium text-titleColor text-xl  "}/>
                  <ButtonSanolla onClick={handleShowModal} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'+ Add Patient'}/>
                </span>
            {showModal &&
            <AddPatientModal onCancel={handleCloseModal}
                                  onSubmit={handleCreatePatient}
            />}


            <Table data={patients?patients:[]} columns={columns} isLoading={isLoading} onClick={(id)=>onRowClick(id)}/>

            {openEditPatient &&
            <AddPatientModal onCancel={()=>setOpenEditPatient(false)}
                             onSubmit={()=>handleEditPatient()}
                             isEdit={true}
                             entry={patient}
            />}
        </div>
    )
}
