import React from "react";
import {TextSanolla} from "./TextSanolla";
import SVGraphics from "../../assets/SVGraphics";

export function PasswordInputSanolla(props) {
    return (
        <div className={`${props.containerClassName}`}>
                <input type={props.type} maxLength={props.maxLength} disabled={props.disabled} name={props.name} className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>
            <TextSanolla text={props.label} className={props.labelClassName}/>
            {
                props.showPassword?
                    <SVGraphics id={props.name} className="text-gray-600 h-4 w-4 fill-current cursor-pointer relative bottom-2/3 right-2 float-right" svgname={'eyeLine'} onClick={props.showOnClick} />
            :
                    <SVGraphics id={props.name} className="text-gray-600 h-4 w-4 fill-current cursor-pointer relative bottom-2/3 right-2 float-right" svgname={'eye'} onClick={props.showOnClick} />
            }

        </div>
    )
}
