import React from "react";
import {TextSanolla} from "./TextSanolla";


export function PhoneInputSanolla(props) {

    return (
        <div className={props.containerClassName}>

            <input type="tel" id="phone" name={props.name}
                   className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value.replace(/[^0-9]+/g, "")}
                   disabled={props.disabled}
                   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                   maxLength="15"
                   required/>
            <TextSanolla text={props.label} className={props.labelClassName}/>
        </div>
    )
}
