import React from 'react';
import "../../style/input/loadingComponent.css"


export function LoadingComponent(){

        return (
            <div className="modalBackground loaderBackground">
            <div className={"loader spin"}/>
            </div>
        );

}

