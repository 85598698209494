import {authenticationService} from "../AuthinticationService";
import Config from "../../config/Config";
import restApi from "./RestApi";

const globalUrl = Config.globalUrl

const DeviceApi = {


    getAllDevices: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return await restApi.get(
                `${globalUrl}api/v1/webapp/devices/${user.profile.UserStorageId}/getAllDevices`,
                requestconfig
            );
        } catch (err) {
            return err
        }


    },
    getOrganizationDevices: async () => {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return (await restApi.get(
                `${globalUrl}api/v1/webapp/devices/${user.profile.UserStorageId}/getOrganizationDevices`,
                requestconfig)
            );
    },
    getDevicesTypes: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/getDevicesTypes`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getDevicesSWversions: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/getDevicesSWversions`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    addDevice: async (body) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/createDevice`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    assignDevice: async (body) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };

            return await restApi.post(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/assignDevice`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    updateDevice: async (body,deviceId) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/${deviceId}/updateDeviceData`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deleteDevice: async (deviceId) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };

            return await restApi.delete(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/${deviceId}/deleteDevice`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getDevice: async (deviceId) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let user = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                },
            };

            return await restApi.get(
                `${globalUrl}/api/v1/webapp/devices/${user.profile.UserStorageId}/${deviceId}/getDeviceById`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    addNewSoftware: async (version,file) => {
        let formData = new FormData()


        if(file){
            formData.append("upgradeFile",file)
            formData.append("SWversion",version.version)
            let fileSize = file.size;
            let fileSizeInMB = fileSize / 1048576;
        }
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/devices/${SystemAdmin.profile.UserStorageId}/uploadNewSWv`,
                formData,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    updateDeviceSW: async (body) => {
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/devices/${SystemAdmin.profile.UserStorageId}/updateDeviceSW`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deleteSW: async (version) => {
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/devices/${SystemAdmin.profile.UserStorageId}/deleteSW`,
                version,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getAllSoftwareVersions: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': SystemAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}api/v1/webapp/devices/${SystemAdmin.profile.UserStorageId}/getAvailableSW`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },


};

export default DeviceApi
