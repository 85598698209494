import React, {useEffect, useRef, useState} from "react";
import {TextSanolla} from "../input/TextSanolla";
import SanollaLogo from "../../assets/SanollaLogo.png"
import SVGraphics from "../../assets/SVGraphics";
import { useNavigate} from "react-router-dom";
import {Overlay, Tooltip} from "react-bootstrap";
import {authenticationService} from "../../services/AuthinticationService";
import {NavBarLogOutPopUp} from "./NavBarLogOutPopUp";
import AppLogout from "../view/auth/AppLogout ";



export function NavBarSanolla(props) {
    const {
        logoutUser
    } = props
    const [navType, setNavType] = useState('organizations');
    const [currentUser, setCurrentUser] = useState(null);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const navigate = useNavigate()
    const types = [
        {name: 'organizations', label: 'Organizations'},
        {name: 'devices', label: 'Devices'},
        {name: 'swVersions', label: 'Software Versions'},
    ]
    useEffect(() => {
        if(window.location.pathname.includes('Devices')){
            setNavType('devices')
        }else if(window.location.pathname.includes('SWVersions')){
            setNavType('swVersions')
        }else{
            setNavType('organizations')
        }
        authenticationService.currentUser.subscribe(
            currentUser => setCurrentUser(currentUser)
        );
    }, [])
    const setType = (type) => {
        switch (type) {
            case 'organizations':
                setNavType(type)
                navigate(`/sanollaAdmin/Organizations`)
                break;

            case 'devices':
                setNavType(type)
                navigate(`/sanollaAdmin/Devices`)
                break;
            case 'swVersions':
                setNavType(type)
                navigate(`/sanollaAdmin/SWVersions`)
                break;
            default:
                setNavType(type)
                navigate(`/sanollaAdmin/Organizations`)
                break;
        }
    }

    return (
        <AppLogout>
            <div
                className="text-4xl font-medium text-center text-gray-500 border-b border-gray-200 max-h-16 bg-white flex items-center justify-center">
                <nav className="flex border-gray-200 px-2 sm:px-4 pt-2.5  items-center  h-16 max-h-[4rem] w-tableWidth">
                    <div className={'flex max-h-full h-1/2 w-full justify-between'}>
                        <img src={SanollaLogo} alt={'Sanolla'} className={""}/>
                        <div className={'flex items-center max-h-full h-full'}>
                            <div className="hidden md:block md:w-auto  " id="navbar-default">
                                <ul className="flex flex-col pt-1 mt-1  px-4  md:flex-row   md:text-lg md:font-medium  ">
                                    {types.map((type) => {
                                        return <li key={type.name}
                                                   className={`mr-12 ${navType === type.name ? "border-b-2 border-sanollaButtonColor" : "border-b-1 border-gray-700"} h-12`}
                                                   onClick={() => setType(type.name)}>
                                            <div
                                                className={`flex items-center pb-3 pr-4 ${navType === type.name ? "text-blue-700 " : "text-gray-700"}`}>
                                                <SVGraphics className="mr-3 h-6 w-6" svgname={type.name}/>

                                                <TextSanolla text={type.label}
                                                             className="cursor-pointer  text-navColor "/>
                                            </div>
                                        </li>
                                    })}
                                </ul>

                            </div>

                        </div>
                        <div ref={target} onClick={() => setShow(!show)}
                             className={" cursor-pointer rounded-full w-8 h-8 bg-[#F3F8FF] text-[0.875rem] text-[#588DE9] font-semibold font-Poppins flex flex-col justify-center"}>
                            {currentUser?currentUser['profile']['FirstName']?.charAt(0).toUpperCase():''}
                        </div>
                        <Overlay
                            placement="bottom"
                            target={target.current}
                            show={show}
                            delay={{show: 250, hide: 400}}>
                            {(props) => (<Tooltip id="button-tooltip" {...props}>
                                <NavBarLogOutPopUp
                                    logoutUser={() => logoutUser()}
                                />
                            </Tooltip>)}
                        </Overlay>
                    </div>
                </nav>
            </div>
        </AppLogout>
    )
}
