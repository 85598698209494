import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from "react-confirm";
import "../../style/errorModal.css"
import {compose} from "redux";
import {withTranslation} from "react-i18next";


class WarningModalComposed extends React.Component {

    // componentDidMount() {
    //     document.getElementById('err-modal-ok-button').focus();
    // }

    render() {
        let {
            proceedLabel,
            title,
            message,
            show,
            proceed,
            cancel,
            enableEscape = true,
            t
        } = this.props;

        return (
            <div id="modalError" tabIndex="-1" aria-hidden="true"
                 className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 rounded-3xl flex justify-start ">
                <div className="w-6/12 h-auto max-w-xl md:h-auto self-center rounded-3xl">
                    <Modal
                        centered={true}
                        show={show}
                        onHide={() => proceed(false)}
                        backdrop={enableEscape ? true : "static"}
                        keyboard={enableEscape}
                        dialogClassName={' h-60 rounded-3xl'}>

                        <Modal.Body>

                            <div
                                className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                                onClick={() => proceed(false)}>
                                &#10005;
                            </div>
                            <div className={'p-8 mt-4 text-left'}>
                                <label className={'text-titleColor font-semibold text-xl mb-8 w-full text-left'}>{title}</label>
                                <div className={'mt-4'}>
                                    {
                                        message
                                    }
                                </div>
                                <div className={'flex justify-between w-1/2 pt-10 float-right pr-3.5'}>
                                    <button className={'bg-transparent w-24 h-12 rounded-xl border-0 text-white border-transparent text-titleColor'} onClick={() => proceed(false)}>{cancel}</button>
                                    <button id={'err-modal-ok-button'} className={'bg-[#FF4860] w-24 h-12 rounded-xl border-0 text-white border-[#FF4860]'}
                                            onClick={() => proceed(true)} onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            proceed(true)
                                        }
                                    }}>{proceedLabel === "Accept"?t('modal.accept'):proceedLabel}</button>

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}

let WarningModal = compose(
    withTranslation()
)(WarningModalComposed)

WarningModal.propTypes = {
    okLabbel: PropTypes.string,
    title: PropTypes.string,
    errMessages: PropTypes.array,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    enableEscape: PropTypes.bool
};

export function makeWarning({proceedLabel,cancel, title,message}) {
    return createConfirmation(confirmable(WarningModal))({
        message,
        proceedLabel,
        cancel,
        title
    });
}
