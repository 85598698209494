import React from "react";
import notFound from "../../../assets/notFound.png"
import {authenticationService} from "../../../services/AuthinticationService";
import {useNavigate} from "react-router-dom";


export function NotFoundPage() {
    const navigate = useNavigate();
    let user = authenticationService.currentUser?.source._value
    let role = user && user['role']?user['role'][0]:null

    const goHome=()=>{

        switch (role){
            case "Physician":
            case "Nurse":
                navigate('/clinician/Patients')
                break;
                case "SanollaAdmin":
                navigate('/sanollaAdmin/Organizations')
                break;
                case "OrganizationAdmin":
                navigate('/admin/Clinicians')
                break;
                case "Technician":
                navigate('/technician/Devices')
                break;
            default:
                navigate('/login')
        }
    }

    return (
        <div className={" m-auto  flex flex-col items-center justify-center relative top-48"}>
        <img src={notFound} alt={"Page Not Found"}/>
            <div className={"text-center text-base text-titleColor font-medium mt-4"}>So sorry,<br/>
                we couldn’t find what you were looking for...</div>
            <button onClick={()=>goHome()} className={"bg-navColor w-32 h-12 text-white rounded-xl mt-8"}>
                Go Home
            </button>

        </div>

    )
}
