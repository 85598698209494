import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import CliniciansApi from "../../services/ApiServices/CliniciansApi";
import Constants from "../../utils/constants"
import {object, string} from "yup";



const AddEditNoteModal = ({onCancel, onSubmit,isEdit,entry,userId}) => {

    const [note, setNote] = useState({})
    const [error, setError] = useState({})
    const [type, setType] = useState(null)
    const [title, setTitle] = useState(null)
    const [subTitle, setSubTitle] = useState(null)
    const [formType, setFormType] = useState(formData['add_Note'])
    const [disabled, setDisabled] = useState(true)
    const [isError, setIsError] = useState(false)

    const noteSchema = object().shape({
        NoteTitle: string().required('Please insert title' ).typeError('Please insert title').default(""),
        Note: string().required('Please insert Note').typeError('Please insert Note').default(""),
    });

    useEffect( () => {
        if(isEdit){
            setTitle('Update Note')
            setType('edit')
            setNote(entry)
            setSubTitle('Carefully update the note information and after\n' +
                'click "Edit"')
        }else{
            setTitle('Create Note')
            setSubTitle('Carefully fill out the note information and after\n' +
                'click "Create"')
        }
     // let modalType =
    }, [isEdit,entry])

    const onChange = async (e) => {
        let name=e.target.name
        let value=e.target.value
        let noteChange = {...note}
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        noteChange[name]=value
        await noteSchema.validate(noteChange,{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                }
            });
        setNote(noteChange)
        setFormType(form)
        setError(formErrors)
    }

    const saveNote = async () =>{
        if(isEdit){
            let editNote = await CliniciansApi.editPatientNote(note, userId)
            if(editNote){
                onSubmit(note)
            }
        }else{
            let addNote = await CliniciansApi.addPatientNote(note, userId)
            if(addNote){
                onSubmit(addNote.data)
            }
        }
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-2/5 h-auto max-w-xl md:h-auto self-center">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[0.938rem] text-left">

                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                {subTitle}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[36.25rem] max-h-[36.25] w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-[26.25rem] w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full"}>
                                <>
                                    <div className={"w-full h-auto py-6 font-semibold text-lg"}>
                                        Note Details
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={note}
                                        onChange={(e) => onChange(e)}
                                        containerClassName={"h-fit w-full "}
                                        labelClassTextArea={"bottom-64"}
                                        inputClassName={Constants.inputClass}
                                        textAreaInputClassName={"bg-white rounded-[12px] h-64 w-full pl-1.5 pt-6 bg-transparent border border-[#A6C3F3] focus:border-1 focus:outline-[#3072E3] focus:bg-transparent font-Poppins"}
                                        isShown={error}
                                        isError={isError}
                                    />

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-[10px] bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() => saveNote()}
                                    disabled={disabled}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {type==='edit'?'Save':'Create'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddEditNoteModal;
