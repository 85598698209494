import React from "react";
import SVGraphics from "../../../../assets/SVGraphics";
import {ActionsMorePopover} from "../../../table/tableMore/ActionsMorePopover";


export function OrganizationDetails(props) {

    let data = JSON.parse(JSON.stringify(props.data))
    let containerClass
    if(props.type==="or")
        containerClass="w-full border border-[#EBF2FC] bg-white h-20 rounded-md flex content-center flex-col justify-center font-Poppins"
    else
        containerClass="w-full border border-[#EBF2FC] bg-[#FAFCFE] h-20 rounded-md flex content-center flex-col justify-center font-Poppins"
    return (
        <div
            className={containerClass}>
            <div className={"w-full flex justify-center content-center h-full "}>
                <div
                    className={"rounded-full bg-[#EBF2FC] w-9 h-8 text-center text-[#588DE9] align-middle flex justify-center content-center flex-col m-4 self-center"}>
                    {data ? data['name']?.charAt(0).toUpperCase() : ''}
                </div>
                <div className={"flex w-full"}>
                    {
                        Object.entries(data).map(([key, value],index) => {
                            return <div key={key} className={"w-max flex justify-around self-center "}>
                                <div className={`${index===0?'pr-7':'px-7'} px-10`}>
                                    <div className={"font-semibold text-base h-6"}>
                                        {value?value:""}
                                    </div>
                                    <div className={"text-[#657690] text-[0.875rem]"}>
                                        {key==='email'?'Email':key==='phone'?'Phone':key==='title'?'Title':key==='name'?'Name':key==='address'?'Address':key==='number'?'Number':key==='type'?'Type':''}
                                    </div>
                                </div>
                                {key!=='email' && key!=='type' &&
                                    <div className={"w-px bg-[#EBF2FC] h-8 self-center"}/>
                                }

                            </div>
                        })
                    }
                </div>
                {
                    props.type==='or'?
                        <div className={'flex justify-between h-1/2 cursor-pointer self-center'}> <SVGraphics className={"w-8 self-center mr-8 ml-8 cursor-pointer"} onClick={props.editOrganization} svgname={`edit`}/></div>
                        :
                        <div className={"flex flex-col justify-center mr-8"}>
                            <ActionsMorePopover
                                class={"mr-6 h-32 w-50 z-10"}
                                closeModal={props.closeModal}
                                style={{marginTop:'16vh'}}
                                cell={ <div className="w-50 h-28 bg-white rounded-xl flex flex-col justify-evenly mt-2">
                                    <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'} onClick={props.edit}><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"} >Edit</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/></div>
                                    <a className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'} href={"mailto:"+props.href} target="_blank"  onClick={props.sendEmail}> <div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"} >Email</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`email`}/></a>
                                    {props.type !=='ad' &&  <div className={'flex justify-between h-1/2 cursor-pointer'} onClick={props.delete}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>}
                                </div>}
                            />
                        </div>
                }

            </div>
        </div>
    )
}
