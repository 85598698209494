import React from "react";
import {TextSanolla} from "./TextSanolla";

const ProgressBarSanolla = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 8,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        // margin: 50,
        float:'left'

    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: "#3072E3",
        borderRadius: 'inherit',
        textAlign: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                {/*<span style={labelStyles}>{`${completed}%`}</span>*/}
            </div>
        </div>
    );
};

    export default ProgressBarSanolla;
