import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import CliniciansApi from "../../services/ApiServices/CliniciansApi";
import Constants from "../../utils/constants"
import { object, string, number } from 'yup';



const AddPatientModal = ({onCancel, onSubmit,isEdit,entry}) => {

    const [patient, setPatient] = useState({})
    const [error, setError] = useState({})
    const [type, setType] = useState(null)
    const [title, setTitle] = useState(null)
    const [isError, setIsError] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [formType, setFormType] = useState(formData['add_Patient'])
    const [count, setCount] = useState(true)
    const phoneRegExp = Constants.phoneRegExp

    const userSchema = object().shape({
        FirstName: string().required().typeError('Please insert First Name').default(""),
        LastName: string().required().typeError('Please insert Last Name').default(""),
        PatientID: number().required().typeError('Please insert Patient ID').positive(),
        Age: number().optional().nullable().positive().integer().default(1),
        Email: string().optional().nullable().email(),
        PhoneNumber: string().required().typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid')
    });
    useEffect(() => {
        setCount(!count)
    },[patient,error])

    useEffect( () => {
        if(isEdit){
            setTitle('Update Patient')
            setType('edit')
            entry['PII']['PatientID']=entry.PatientID
            setPatient(entry.PII)
        }else{
            setTitle('Add Patient')
        }
     // let modalType =
    }, [isEdit,entry])

    const onChange = async (e) => {
        let newPatient = {...patient}
        let name = e.target.name
        let value = e.target.value
        let form = [...formType]
        let formErrors = {...error}
        if(name === 'PatientID'){
            value=value.replace(/[^0-9.]/g,'')
        }
        if (value === "") {
            value = null
        }
        newPatient[name] = value
        await userSchema.validate(newPatient,{abortEarly: false}).then(function(value) {
                formErrors[name] = false
                setDisabled(false)
            setIsError(false)
            })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                    // setIsError(false)
                }
            });
        setPatient(newPatient)
        setFormType(form)
        setError(formErrors)
    }
    const createPatient = async () => {
        patient['Age'] = String(patient['Age'])
        let submit
        if(type==='edit'){
            submit =await CliniciansApi.editPatient(patient,entry.UserStorageId)
            if(submit && submit.data){
                onSubmit(submit.data.patient)
            }
        }else{
            submit =await CliniciansApi.createPatient(patient)
            if(submit && submit.data){
                onSubmit()
            }
        }
    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:top-0 md:left-0 md:right-0 h-modal md:h-modal bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-full max-w-xl md:h-modal self-center top-0 md:top-16">
                <div className="relative bg-white rounded-2xl shadow dark:bg-gray-700 top-0 ">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">
                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[0.938rem] text-left">
                                {title}
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                Carefully fill out the patient's information and
                                click "Create"
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-5/6 w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-5/6 w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>
                            <div className={"w-full"}>
                                <>
                                    <div
                                        className={"w-full h-auto py-6 font-semibold text-lg"}>Patient Details
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={patient}
                                        onChange={(e) => onChange(e)}
                                        containerClassName={"h-14 w-full "}
                                        inputClassName={Constants.inputClass}
                                        isShown={error}
                                        isError={isError}
                                    />

                                </>
                            </div>
                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() => createPatient()}
                                    disabled={ disabled}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {type==='edit'?'Update':'Create'}
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddPatientModal;
