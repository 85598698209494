import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import utils from "../../utils/utils";
import SuperAdminApi from "../../services/ApiServices/SuperAdminApi";
import Constants from "../../utils/constants"



const AddContactPersonModal = ({onCancel, onSubmit,entry,organizationId}) => {

    const [contactPerson, setContactPerson] = useState({})
    const [error, setError] = useState(false)
    const [isError, setIsError] = useState(false)

    const formType = formData['add_organization']['page_3']

    useEffect( () => {
        setContactPerson(entry)
    }, [])

    const onChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        let _contactPerson = {...contactPerson}
        if (value === "") {
            value = null
        }
        if(name==="ContactPersonEmail"){
            if(value && !utils.mailValidation(value)){
                setError({ContactPersonEmail:true})
                setIsError(true)
            }else{
                setError({ContactPersonEmail:false})
                setIsError(false)
            }
        }
        _contactPerson[name] = value
        setContactPerson(_contactPerson)
    }
    const editContactPerson = async () => {
        //organizationId
        let contact = {ContactPersonName:contactPerson.ContactPersonName,
            ContactPersonEmail:contactPerson.ContactPersonEmail,
            ContactPersonPhone:contactPerson.ContactPersonPhone
        }
        let submit =await SuperAdminApi.EditOrganizationContactPerson(contact,organizationId)
            if(submit && submit.data){
                onSubmit(submit.data)
        }
    }


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0  left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-2/5 h-auto max-w-xl md:h-auto self-center">
                <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700 ">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-4/5 px-auto justify-center justify-items-center">

                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[1] text-left">

                                Edit Contact Person
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">
                                To update contact person's information, simply fill out the provided form with your updated details
                            </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-[25rem] max-h-full w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto h-[24.375rem] w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>

                            <div className={"w-full"}>

                                <>
                                    <div
                                        className={"w-full h-auto py-6 font-semibold text-lg"}>Contact Person Details
                                    </div>
                                    <SanollaForm
                                        data={formType}
                                        formData={contactPerson}
                                        onChange={(e) => onChange(e)}
                                        containerClassName={"h-14 w-full "}
                                        inputClassName={Constants.inputClass}
                                        isShown={error}
                                        isError={isError}
                                    />

                                </>
                            </div>


                        </div>

                    </div>
                    <div
                        className="flex py-6 space-x-2 w-4/5 rounded-b justify-self-center justify-end mx-auto">
                            <button type="button"
                                    onClick={()=>onCancel()}
                                    className="text-black font-semibold mr-2.5 bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                close
                            </button>
                            <button type="button"
                                    onClick={() =>editContactPerson()}
                                    disabled={(contactPerson['ContactPersonEmail'] && !utils.mailValidation(contactPerson['ContactPersonEmail'])) || (contactPerson['ContactPersonEmail'] && contactPerson['ContactPersonEmail'].length<10)}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                Edit
                            </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}
export default AddContactPersonModal;
