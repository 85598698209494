import React from "react";



export function NavBar(props) {

    return (
        <div className="text-4xl w-full font-medium text-center text-gray-500 border-b border-gray-200 max-h-[4rem]">
            <nav className="border-gray-200 w-full px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
                <div className="container w-full flex flex-wrap justify-between items-center">
                    <div className="hidden w-full md:block md:w-full" id="navbar-default">
                        {props.list}
                    </div>
                </div>
            </nav>
        </div>
    )
}
