import React, {useEffect, useRef, useState} from "react";
import formData from "../../forms/formsData.json";
import {SanollaForm} from "../../forms/SanollaForm";
import {useNavigate} from "react-router-dom";
import utils from "../../../utils/utils";
import {Error} from "../../forms/Error";
import {TextSanolla} from "../../input/TextSanolla";
import SVGraphics from "../../../assets/SVGraphics";
import Constants from "../../../utils/constants"
import LoginAPICalls from "../../../services/ApiServices/LoginAPICalls";
import {authenticationService} from "../../../services/AuthinticationService";
import {LoadingComponent} from "../../input/LoadingComponent";
import { object, string, ref } from "yup";
import ReCaptcha from "./ReCaptcha";


export function NewPassword() {
    const [newPasswordInputs, setNewPasswordInputs] = useState({});
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [formType, setFormType] = useState(formData['changePassword'])
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState({'newPassword':false,'confirmNewPassword':false,'oldPassword':false});
    const [BackendErrors, setBackendErrors] = useState(null);
    const [disabled, setDisabled] = useState(true)
    const [count, setCount] = useState(true)
    const passRegex = Constants.passwordPattern
    const captchaRef = useRef();

    const newPassSchema = object().shape({
        oldPassword: string().required().typeError("Password is Required"),
        newPassword:string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[@$!%*?&])(?=.*[A-Z]).{8,20}$/,"Please enter valid password - password must contain 8-20 characters with at least one uppercase, one lowercase, one numeric character, and one symbol.").required().typeError('Password is required'),
        confirmNewPassword: string().oneOf([ref('newPassword'), null], 'Passwords must match'),
    });

    useEffect(() => {
        setCount(!count)
    },[newPasswordInputs,error])

    const SubmitFunc = async (reCaptchaToken) => {
        let _newPasswordInputs = {...newPasswordInputs}
        _newPasswordInputs['reCaptchaToken'] = reCaptchaToken
        setIsLoading(true)
        let submit = await LoginAPICalls.changeNewPassword(newPasswordInputs)
        if(submit && submit.data && submit.status < 400){
            navigate(utils.navigateAccordingToRole(authenticationService.currentUserValue.role[0]))
            // navigate('/login')
        }
        setIsLoading(false)
    }

    const handleChange = async (event) => {
        let inputs = {...newPasswordInputs}
        let name = event.target.name
        let value = event.target.value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            delete inputs[name]
        }
        inputs[name]=value

        setNewPasswordInputs(inputs)
        setBackendErrors(null)

        newPassSchema.validate(inputs).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
        }).catch(function(err) {
                if(err){
                    setIsError(true)
                    let index = form.findIndex(x=>x.name===name)
                    if(err.path===name){
                        form[index]['error'] = err.errors
                        formErrors[name] = true
                        setDisabled(true)
                    }
                }
                else{
                    formErrors[name] = false
                    setDisabled(false)
                }
            });
        switch (name) {
            case 'newPassword':
                if(value && !utils.passwordValidation(value)){
                    formErrors['newPassword']=true
                    setIsError(true)
                    form[1]['error'] = ["Please enter valid password - password must contain 8-20 characters with at least one uppercase, one lowercase, one numeric character, and one symbol."]
                }
                else{
                    formErrors['newPassword']=false
                    // setIsError(false)
                    form[1]['error'] = [""]
                }
                break;
            default:  setIsError(false)
        }
        setFormType(form)
        setError(formErrors)
    }

    const setShowPasswordType = ( e ) => {

        let isShow ={...showPassword}
        isShow[e.target.id] = !isShow[e.target.id]
        setShowPassword(isShow)

    }
    const ExecuteRecaptcha = async (reCaptchaToken) => {
        // await captchaRef.reset()
        await captchaRef.current.reset()
        await captchaRef.current.execute()
    }

    return (
        <div className={"w-[28rem] h-[35.75rem] m-auto bg-white shadow-2xl rounded-3xl flex items-center justify-center relative top-44 "}>
            <div className={"flex flex-col justify-center w-4/6"}>
                <TextSanolla text={"Update Password"} className={"text-titleColor text-3xl font-Rubik font-semibold text-center w-full " }/>
                <div className={"text-titleColor text-l font-Poppins mt-2 text-center"}>You are about to create a new password that you will connect with</div>


                <div className={"mt-12"}>
                    <SanollaForm
                        data={formType}
                        formData={newPasswordInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"h-14 w-full "}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        type={showPassword}
                        showOnClick={(e)=>setShowPasswordType(e)}
                        isError={isError}

                    />

                </div>
                <div className={'flex w-full justify-center items-center h-12'}>
                    <SVGraphics className={'h-full w-2/12 mr-2 mt-8 cursor-pointer'}
                                svgname={'back'}
                                onClick={()=>navigate('/forgotPassword')}
                    />
                    <button type="button"
                            onClick={() => SubmitFunc()}
                            //disabled={!newPasswordInputs['oldPassword'] || !newPasswordInputs['newPassword'] || !utils.passwordValidation(newPasswordInputs['newPassword']) || !newPasswordInputs['confirmNewPassword'] || (newPasswordInputs['confirmNewPassword'] !== newPasswordInputs['newPassword'])|| (newPasswordInputs['oldPassword'] === newPasswordInputs['newPassword'])}
                            disabled={disabled}
                            className="text-white mt-8 w-10/12 h-12 bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                        Confirm
                    </button>

                </div>

                <div className={"w-full text-center mt-2"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>

                {isLoading &&
                <LoadingComponent/>
                }

            </div>
            {/*<ReCaptcha*/}
            {/*    refReCaptcha={captchaRef}*/}
            {/*    action={async (reCaptchaToken) => {*/}
            {/*        await SubmitFunc(reCaptchaToken)*/}
            {/*    }}*/}
            {/*    setErr={console.log}/>*/}
        </div>

    )
}
