import React, {useCallback, useState} from "react";
import Dropzone from "../input/DropZone"


// const Image = ({ image }) => {
//     return (
//         <div className="file-item">
//             <img
//                 alt={`img - ${image.id}`}
//                 src={image.src}
//                 className="file-img"
//             />
//         </div>
//     );
// };

export function InputFileSanolla(props) {

    // const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const [files, setFiles] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        props.onChange({target:{name:'files',files:acceptedFiles}})
        acceptedFiles.map((file) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                setFiles((prevState) => [
                    ...prevState,
                    { id: String(new Date()), src: e.target.result },
                ]);
            };


            reader.readAsDataURL(file);
            return file;
        });
    }, []);
    // const ImageGrid = ({ images }) => {
    //     // render each image by calling Image component
    //     const renderImage = (image, index) => {
    //         return <Image image={image} key={`${image.id}-image`} />;
    //     };
    //     // Return the list of files//
    //     return (
    //         <section className="file-list">{images.map(renderImage)}</section>
    //     );
    // };
    return (
        <div className={props.containerClassName}>
            <div >
            {/*<input type={"file"} id={"file"} disabled={props.disabled} name={props.name} className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange}/>*/}
                <Dropzone onDrop={onDrop} containerClass={"h-40 border border-2 border-dashed border-slate-300 rounded-xl"} acceptedFiles={props.accept} />
            </div>



        </div>
    )
}
