import {authenticationService} from "../AuthinticationService";
import Config from "../../config/Config";
import restApi from "./RestApi";

const globalUrl = Config.globalUrl

const SuperAdminApi = {


    editAdmin: async (body) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;

            return await restApi.post(
                `${globalUrl}/api/v1/webapp/superAdmin/${SystemAdmin.profile.UserStorageId}/editAdmin`,
                body
            );
        } catch (err) {
            return err
        }
    },

    getAllOrganizations: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': SystemAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/getAllOrganizations`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getOrganizationTypes: async () => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': SystemAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}/api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/getOrganizationTypes`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getOrganization: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': SystemAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/${id}/getOrganization`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getAllTechnician: async (id) => {
        try {
            if (!authenticationService.currentUserValue)
                return;
            let SystemAdmin = authenticationService.currentUserValue;
            const requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': SystemAdmin.token,
                },
            };
            return await restApi.get(
                `${globalUrl}api/v1/webapp/technician/${SystemAdmin.profile.UserStorageId}/${id}/getAllTechnician`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },


    addOrganization: async (body,logo) => {
        let formData = new FormData()
        formData.append("OrganizationData",JSON.stringify(body))
        if(logo){
            formData.append("OrganizationLogo",logo)
        }
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/createOrganization`,
                formData,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    uploadNewOrganizationLogo: async (logo,id) => {
        let formData = new FormData()
        if(logo){
            formData.append("OrganizationLogo",logo)
        }
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/${id}/uploadNewOrganizationLogo`,
                formData,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    EditOrganizationInfo: async (body,id) => {
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/${id}/EditOrganizationInfo`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    EditOrganizationAdmin: async (body,id) => {
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/users/${SystemAdmin.profile.UserStorageId}/${id}/editOrgAdmin`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    EditOrganizationContactPerson: async (body,id) => {
        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            return await restApi.post(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/${id}/EditOrganizationContactPerson`,
                body,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    deleteOrganizationContactPerson: async (id) => {

        let SystemAdmin = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SystemAdmin.token,
            },
        };
        try {
            if (!authenticationService.currentUserValue)
                return;
            // let SystemPhysicians = authenticationService.currentUserValue;

            return await restApi.post(
                `${globalUrl}api/v1/webapp/organizations/${SystemAdmin.profile.UserStorageId}/${id}/deleteOrganizationContactPerson`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },




};

export default SuperAdminApi
