import axios from 'axios'
import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const globalUrl = process.env.REACT_APP_API_ENDPOINT

const LoginAPICalls = {
    signIn: async (username, password,reCaptchaToken) => {
        let body = {
            "Email": username,
            "Password": password,
            "reCaptchaToken":reCaptchaToken
            // "reCaptchaToken": reCaptchaToken
        };
        try {
            return await axios.post(
                `${globalUrl}/api/v1/webapp/users/signin`,
                body
            );
        } catch (err) {
            return err
        }
    },
    changeNewPassword: async (password) => {
        if (!authenticationService.currentUserValue)
            return;
        let user = authenticationService.currentUserValue;
        const requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token,
            },
        };
        try {
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/users/changePass/${user.profile.UserStorageId}`,
                password,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

    submit2fa: async (code) => {
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentMfaUserValue.token,
            }
        };
        try {
            return await restApi.post(
                `${globalUrl}/api/v1/webapp/users/signin/2fa`,
                {TwoFaCode: code},
                requestconfig
            );
        } catch (err) {
            return err
        }
    },
    getVersion: async () => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            return await axios.get(
                `${globalUrl}/api/v1/webapp/users/getVersion`,
                requestconfig
            );
        } catch (err) {
            return err
        }
    },

    forgetPass: async (Email) => {
        try {
            return await axios.post(
                `${globalUrl}api/v1/webapp/users/forgetPass`,
                Email
            );
        } catch (err) {
            return err
        }
    },
    refreshToken: async (refreshToken) => {
        if (!authenticationService.currentUserValue)
            return;
        let user = authenticationService.currentUserValue;
        let body = {
            email: (user['profile'])['email'],
            refreshToken
        };
        let url = `${globalUrl}/api/v1/users/refreshToken`;
        return await restApi.post(
            url,
            body,
            {headers: {'Content-Type': 'application/json'}}
        );
    },

};

export default LoginAPICalls
