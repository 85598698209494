import React, {useEffect, useState} from "react";
import more from '../../../assets/More_Horizontal.png'



export function ActionsMorePopover(props) {
    const [showModal, setShowModal] = useState(false);

    useEffect( () => {
        if(!props.closeModal)
            setShowModal(false)
    }, [props.closeModal])


    const isShowModal = () => {
        // props.modalDisplay(!showModal)
        setShowModal(!showModal)
    }

    return (
    <>
        <div className={`rounded-full w-8 h-8 ${showModal?'bg-[#F3F8FF]':'bg-transparent'} text-center flex justify-center`}>
        <img onClick={()=>isShowModal()} className={"hover:opacity-50 py-1"} src={more} alt={'more'}/>
        </div>
            <div className={`${showModal?'block':'hidden'} w-48 ${props.class?props.class:'h-28'}  border-[#A6C3F3] absolute right-2.5 rounded-xl border bg-white mt-6`}
            style={props.style}
            >
                {props.cell}
            </div>
        </>
            )
}
