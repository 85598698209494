import React, {useState, useEffect} from "react";
import {SanollaForm} from "../forms/SanollaForm";
import formData from "../forms/formsData.json"
import ProgressBarSanolla from "../input/ProgressBarSanolla";
import v_sign from "../../assets/v_sign.png"
import down from "../../assets/arrows/down.svg";
import utils from "../../utils/utils";
import SuperAdminApi from "../../services/ApiServices/SuperAdminApi";
import Constants from "../../utils/constants"
import {object, string} from "yup";


const AddOrganizationModal = ({ onCancel, onSubmit}) => {


    const [page, setPage] = useState(1);
    const [FormData, seFormData] = useState(formData['add_organization']);
    const [formType, setFormType] = useState(FormData['page_1'])//form data from json file to build the add organization form
    const [formTitle, setFormTitle] = useState("Organization Details")
    const [organization, setOrganization] = useState({organization: {}, admin: {}, contactPerson: {}, OrganizationLogo: {}})
    const [fileName, setFileName] = useState(null)
    const [data, setData] = useState(organization['organization'])
    const [type, setType] = useState('organization')
    const [error, setError] = useState({})
    const [isError, setIsError] = useState({'page_1': false, 'page_2': false, 'page_3': false, 'page_4': false})
    const [previewImage, setPreviewImage] = useState(null)
    const [isCollapse, setIsCollapse] = useState({
        organization: true,
        admin: true,
        contactPerson: true,
        OrganizationLogo: true
    })
    const [count, setCount] = useState(true)
    const [disabled, setDisabled] = useState(true)
    const phoneRegExp = Constants.phoneRegExp
    useEffect(() => {
        setCount(!count)
    },[organization,error])

    const organizationSchema = object().shape({
        Name: string().required().typeError('Please insert Name').default(""),
        OrganizationNumber: string().required('Please insert Number').typeError('Please insert Number').default(""),
        Address: string().optional().nullable().default(""),
        OrganizationType: string().optional().nullable().default(""),
    });
    const organizationAdminSchema = object().shape({
        FirstName: string().required('Please insert First Name').typeError('Please insert First Name').default(""),
        LastName: string().required('Please insert Last Name').typeError('Please insert Last Name').default(""),
        Email: string().required().typeError('Please insert Email').email('Please insert Valid Email').default(""),
        PhoneNumber: string().required().typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
    });
    const contactPersonSchema = object().shape({
        ContactPersonName: string().optional().nullable().default(""),
        ContactPersonEmail: string().optional().email('Email not valid').nullable().default(""),
        ContactPersonPhone: string().optional().nullable().default("").matches(phoneRegExp, 'Phone number is not valid').nullable().default(""),
    });

    useEffect(() => {
        const fetchData =  async () =>{
            let data =await SuperAdminApi.getOrganizationTypes()
            let typeData=[]
            if(data && data.data){
                data.data.forEach(d=>{
                    typeData.push({value:d,
                        label: d})
                })
            }
            let types=[...formType]
            types[3]["data"]=typeData
            setFormType(types)
        }
        fetchData().then()
    },[])
    useEffect(() => {
        switch (page) {
            case 1:
                setFormType(FormData['page_1'])
                setFormTitle("Organization Details")
                setData(organization['organization'])
                break;
            case 2:
                setFormType(FormData['page_2'])
                setFormTitle("Admin Person")
                setType("organization administrator's")
                setData(organization['admin'])
                break;
            case 3:
                setFormType(FormData['page_3'])
                setFormTitle("Contact Person")
                setData(organization['contactPerson'])
                setType("contact person's")
                break;
            case 4:
                setFormType(FormData['page_4'])
                setFormTitle("Organization Logo")
                setData(organization['OrganizationLogo'])
                break;
            case 5:
                setFormTitle("Organization Details")

                break;
            default:
                setFormType(FormData['page_1'])
                setFormTitle("Organization Details")
                setData(organization['organization'])
                break;
        }
    }, [page]);

    const setCollapsePage = (page) =>{
        let newIsCollapse = {...isCollapse}
        newIsCollapse[page] = !newIsCollapse[page]
        setIsCollapse(newIsCollapse)
    }

    const setPageForm = (type) => {
        let newPage = type === 'next' ? page + 1 : page - 1
                setPage(newPage)
    }

    const onChange = async (e,key) => {
        let name = e.target.name
        let value = e.target.value
        let isErrorForm = {...isError}
        let formData = {...FormData}
        let form = [...formType]
        let formErrors = {...error}
        let org = {...organization}

        let currentPage
        if(key){
            form = formData[key]
            currentPage = key === "page_1" ?
                1 : key === "page_2" ?
                   2:key === "page_3" ?
                   3:key === "page_4" ?
                   4:""
        }  else{
            key = page===1?"page_1":page===2?"page_2":page===3?"page_3":"page_4"
            currentPage=page
        }

        let validationType =currentPage===1?organizationSchema:currentPage===2?organizationAdminSchema:currentPage===3?contactPersonSchema:null
        let type =currentPage===1?'organization':currentPage===2?'admin':currentPage===3?'contactPerson':currentPage===4?'OrganizationLogo':null
        switch (currentPage) {
            case 1:
                if(name==="OrganizationNumber"){
                    value=value?.replace(/[^\d]+/g,'')
                }
                org['organization'][name] = value
                break;
            case 2:
                if(name==="PhoneNumber"){
                    value=value?.replace(/[^\d]+/g,'')
                }
                org['admin'][name] = value
                break;
            case 3:
                if(name==="ContactPersonPhone"){
                    value=value?.replace(/[^\d]+/g,"")
                    if (value === "") {
                        value = null
                    }
                }
                org['contactPerson'][name] = value
                break;
            case 4:
                org['OrganizationLogo'][name] = e.target.files[0]
                if (e.target.files && e.target.files[0]) {
                    setFileName(e.target.files[0]['name'])
                    setPreviewImage(URL.createObjectURL(e.target.files[0]));
                }
                break;
            default:
                org['organization'][name] = value
        }
        await validationType.validate(org[type],{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
            isErrorForm[key]=false
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                        formErrors[error.path] = true
                        formData[key][index]['error'] = error.errors

                        setDisabled(true)
                        isErrorForm[key]=true
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    formErrors[name] = false
                    // form[key][index]['error'] = []
                    formData[key][index]['error'] = []
                }
            });
        seFormData(formData)
        setOrganization(org)
        setFormType(form)
        setError(formErrors)
        setIsError(isErrorForm)
    }

    const saveOrganization = async () =>{
            let organizationData ={
                Organization: organization['organization'],
                OrganizationAdmin: organization['admin'],
                OrganizationContactPerson:organization['contactPerson']
            }
            let logo =organization['OrganizationLogo']['OrganizationLogo']?organization['OrganizationLogo']['OrganizationLogo']:null
            let response = await SuperAdminApi.addOrganization(organizationData,logo)
        if(response && response.data){
            onSubmit(response.data['organization'])
        }
    }
    const deletePhoto = () => {
        let _organization = {...organization}
        _organization['OrganizationLogo']={}
        setPreviewImage(null)
        setOrganization(_organization)
        setFileName(null)

    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:top-0 md:left-0 md:right-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="w-[37rem] h-full max-w-xl md:h-modal self-center top-0 md:top-16">
                <div className="relative bg-white rounded-2xl shadow dark:bg-gray-700 top-12">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                        onClick={onCancel}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center p-5 w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-2xl w-4/5 px-auto justify-center justify-items-center">
                            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white mt-[0.938rem] text-left">
                                {page !== 5 ?
                                    'Add Organization' : `Review New Organization`
                                }
                            </h3>
                            <h5 className=" text-gray-900 dark:text-white mt-[0.938rem] text-left">

                                {page !==5 ?
                                    `Carefully fill out the ${type} information and click "Next"`
                                :
                                    'Carefully review all of the organization\'s information and click "Create"'
                                } </h5>
                        </div>
                    </div>
                    <div
                        className={`mx-auto h-fit max-h-[35.938rem] w-4/5 flex justify-self-center rounded-2xl addOrganizationModal overflow-auto border border-{#EBF2FC}`}
                        style={{backgroundColor: '#FAFCFE'}}>
                        <div
                            className={`mx-auto ${page !== 5 ? "h-[26.25rem]" : "h-[33.125rem]"} w-4/5 justify-self-center rounded-2xl addOrganizationModal `}>
                            <div className={"w-full"}>
                                {page !== 5 ?
                                    <>
                                        <div
                                            className={"w-full h-auto py-6 font-semibold text-lg"}>{formTitle}</div>
                                        <SanollaForm
                                            data={formType}
                                            formData={data}
                                            onChange={(e) => onChange(e)}
                                            containerClassName={"h-14 w-full "}
                                            inputClassName={Constants.inputClass}
                                            isShown={error}
                                            fileName={fileName}
                                            deletePhoto={()=>deletePhoto()}
                                            isError={isError[page===1?'page_1':page===2?'page_2':page===3?'page_3':'page_4']}
                                            previewImage={previewImage}
                                        />
                                    </> :
                                    <div>{
                                        Object.entries(FormData).map(entry => {
                                            const [key, value] = entry;
                                            return (<div key={key} className={"w-full mt-6"}>
                                                <div className={"w-full flex mb-2.5 justify-between"}>
                                                    <div className={"flex"}>
                                                        <img src={v_sign} alt="v"
                                                             className={"mr-2 w-6 h-6"}/>
                                                        <div
                                                            className={"text-lg font-semibold"}>{utils.getCurrentPageTitle(key)}</div>
                                                    </div>
                                                    <div className={"cursor-pointer relative" } onClick={()=>setCollapsePage(utils.getCurrentPage(key))}>
                                                        {!isCollapse[utils.getCurrentPage(key)]
                                                            ? <img className={'inline-block ml-[0.438rem] w-2.5'} alt={'down'}
                                                                   src={down}/>
                                                            : <img className={'rotate-180 inline-block ml-[0.438rem] w-2.5'} alt={'up'}
                                                                   src={down}/>
                                                        }
                                                    </div>
                                                </div>
                                                {isCollapse[utils.getCurrentPage(key)] &&
                                                <>
                                                    <SanollaForm
                                                        data={value}
                                                        formData={organization[utils.getCurrentPage(key)]}
                                                        onChange={(e) => onChange(e, key)}
                                                        containerClassName={"w-full h-[56px]"}
                                                        inputClassName={Constants.inputClass}
                                                        isShown={error}
                                                        deletePhoto={()=>deletePhoto()}
                                                        fileName={fileName}
                                                        isError={isError[key]}
                                                        previewImage={previewImage}
                                                    />
                                                </>
                                                }
                                                <div className={"mt-[24px] bg-[#EBF2FC] relative"}
                                                     style={{height: '0.5px', width: '123%', right: '11%'}}/>
                                            </div>)
                                        })
                                    }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex items-center p-6 space-x-2  border-t  mt-[40px] border-gray-200 rounded-b justify-between">
                        <div className={"flex-col w-[128px]"}>
                            {page !== 5 ?
                                <div
                                    className={"w-full text-[#0957DE] font-semibold text-[0.875rem] pb-2"}>{`page ${page} of 4`}</div> :
                                <div className={"w-full text-[#0957DE] font-semibold text-[0.875rem] pb-2"}>Final
                                    Step  &#127942;</div>
                            }
                            <ProgressBarSanolla
                                completed={page === 1 ? 25 : page === 2 ? 50 : page === 3 ? 75 :page === 4 ? 100 : 100}
                            />
                        </div>
                        <div>
                            <button type="button"
                                    onClick={() => page !== 1 ? setPageForm('back') : onCancel()}
                                    // disabled={page === 1}
                                    className="text-black font-semibold mr-[10px] bg-white enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-transparent text-base px-5 py-2.5 enabled:hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                Back
                            </button>
                            <button type="button"
                                    onClick={() => page !== 5 ? setPageForm('next') : saveOrganization()}
                                    //disabled={disabled()}
                                disabled={disabled}
                                    className="text-white bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                                {page !== 5 ?"Next":"Create"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddOrganizationModal;
