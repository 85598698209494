import React, {useState, useEffect, useCallback} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import SVGraphics from "../../../assets/SVGraphics";
import DeviceApi from "../../../services/ApiServices/DeviceApi";
import OrganizationAssignDeviceModal from "../../modals/OrganizationAssignDeviceModal";
import UpdateSoftwareModal from "../../modals/UpdateSoftwareModal";


export function DevicesTechnician() {

    const [showModal, setShowModal] = useState(false);
    const [showUpdateSW, setShowUpdateSW] = useState(false);
    const [devices, setDevices] = useState([]);
    const [editData, setEditData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAssignDevice, setIsAssignDevice] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = React.useState([]);
    const [clearCheckBox, setClearCheckBox] = useState(false);

    useEffect( () => {
        fetchData().then()
    }, [])
    const fetchData= async()=> {
        let _devices = await DeviceApi.getAllDevices()
        if(_devices && _devices.data){
            setDevices(_devices.data)
            setFilteredData(_devices.data)
            setIsLoading(false)
        }
    }

    const columns = [
        {
            Header: 'Type',
            accessor: 'Type', // accessor is the "key" in the data
        },
        {
            Header: 'Device Version',
            accessor: 'SWVersion',
        },
        {
            Header: 'Version Status',
            accessor: 'DeviceVersionStatus',

        },

        {
            Header: 'License Code',
            accessor: 'LicenseValidityCode',
        },
        {
            Header: 'S/N',
            accessor: 'SN',
        },
        {
            Header: 'BLE MAC',
            accessor: 'BLEMACAddress',
        },
        {
            Header: 'Warranty End',
            accessor: 'WarrantyExpiration',
        },
        {
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({ cell }) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer p-1'}
                                     // onClick={()=>openEditDevice(cell.row)}
                                ><div className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"} onClick={()=>handleOpenUpdateSW(cell.row.original)}>Update</div> <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`update`}/></div>
                                <div className={'flex justify-between h-1/2 cursor-pointer'}><div className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete</div> <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/></div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]

    const openAssignDevice = async () => {
        let device = await DeviceApi.getAllDevices()
        if(device && device.data){
            let _devices = []
            device.data.forEach(item=>{
                if(!item.OrganizationID){
                    _devices.push(item.BLEMACAddress)
                }
            })
            setEditData(_devices)

        }
        setIsAssignDevice(true)
    }
    const handleAssignDevice = () => {
        fetchData().then(p=>{
            setDevices(p.data)
            setFilteredData(p.data)
            setIsAssignDevice(false)
        })
    }
    const handleSearch=(event)=>{
        let filterValue = event.target.value
        let filteredData =[]
        for (let i = 0; i < devices.length; i++) {
            filteredData = devices.filter(device =>
                String(device['BLEMACAddress']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['DeviceVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SN']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['Type']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                String(device['SWVersion']).toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            )
        }
        setFilteredData(filteredData)
    }

    const handleOpenUpdateSW = useCallback((device) => {
        if(device && device['BLEMACAddress']){
            setSelectedRowIds([device])
        }
        setShowUpdateSW(true);
    }, [showModal]);
    const handleCloseUpdateSW = useCallback(() => {
        setSelectedRowIds([])
        fetchData().then()
        setClearCheckBox(!clearCheckBox)
        setShowUpdateSW(false);
    }, [showModal]);
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 ">
            <SearchInput
                onChange={(e)=>handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Devices List"} className={"font-medium text-titleColor text-xl  "}/>
                  <div>
                 <ButtonSanolla onClick={handleOpenUpdateSW} disabled={selectedRowIds.length===0} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor mr-4 disabled:bg-gray-300 disabled:border-gray-300'} label={'+ Update Software'}/>
                <ButtonSanolla onClick={openAssignDevice} containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'} label={'Assign Device'}/>
                  </div>
                  </span>

            {isAssignDevice &&
            <OrganizationAssignDeviceModal
                data={editData}
                onCancel={()=>setIsAssignDevice(false)}
                onSubmit={handleAssignDevice}

            />
            }
            {showUpdateSW && selectedRowIds.length>0 &&
            <UpdateSoftwareModal
                data={editData}
                onSubmit={handleCloseUpdateSW}
                onCancel={handleCloseUpdateSW}
                selectedRowIds={selectedRowIds}

            />
            }
            <div className={"w-full h-[720px] overflow-auto"}>
                    <Table data={filteredData} columns={columns} isLoading={isLoading} headerClassName={"text-[0.875rem]"}  onRowSelectStateChange={setSelectedRowIds} clearCheckBox={clearCheckBox}/>

            </div>
        </div>
    )
}
