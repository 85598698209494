import React, {useState} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import {useNavigate} from "react-router-dom";
import {InputSanolla} from "../../input/InputSanolla";
import {TextSanolla} from "../../input/TextSanolla";
import SVGraphics from "../../../assets/SVGraphics";
import utils from "../../../utils/utils";

export function VerificationCode() {

    const navigate = useNavigate();
    const [code, setCode] = useState(null);

    const SubmitFunc = async () => {


        /*        setFormErrors(errors)
                console.log("errors" , errors)*/

        let response = await authenticationService.submit2fa(code);
        if (response && response.status < 400) {
            let data = response.data
            if (data.PassNeedsToChange) {
                navigate('/newPassword')
            } else {
                navigate(utils.navigateAccordingToRole(data.hasOwnProperty('role') && data['role'][0]))
            }
        }
    }



    return (
        <div
            className={"w-[28rem] h-[26.25rem] m-auto bg-white shadow-2xl rounded-3xl flex items-center justify-center relative top-44 text-center"}>
            <div className={"flex flex-col justify-center w-4/6"}>
                <TextSanolla text={"Verification Code"} className={"text-titleColor text-3xl font-Rubik font-semibold text-center w-full " }/>
                {/*<img src={logo} alt={"Sanolla"} className={"flex w-36 self-center"}/>*/}
                <div className={"text-titleColor text-l font-Poppins mt-2"}>Enter the code displayed in your authenticator mail</div>
                <div className={"mt-14 "}>
                    <InputSanolla
                        inputClassName={"bg-white rounded-xl h-14 w-full pl-1.5 pt-2 h-full bg-transparent border border-[#A6C3F3] focus:border-1 focus:outline-[#3072E3] focus:bg-transparent font-Poppins"}
                        containerClassName={"h-14 w-full "}
                        onChange={e=>setCode(e.target.value)}
                        labelClassName={`${code?'inline':'hidden'} text-{#657690} relative text-xs pl-1.5 pt-1 bottom-14`}
                        placeholder={"Code"}
                        lable={"Code"}
                        name={"code"}
                    />

                    {/*<div*/}
                    {/*    className={"w-full text-right relative bottom-3 mb-2.5 text-titleColor cursor-pointer text-[0.875rem] font-Poppins"}>Forgot*/}
                    {/*    password?*/}
                    {/*</div>*/}

                </div>
                <div className={'flex w-full justify-center items-center'}>
                        <SVGraphics className={'h-12 w-12 mr-2 mt-8'}
                                    svgname={'back'}/>
                    <button type="button"
                            onClick={() => SubmitFunc()}
                            disabled={!code}
                            className="text-white mt-8 w-10/12 h-12 bg-navColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-[0.875rem] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-25">
                        Verify
                    </button>

                </div>


                <div className={'text-base text-titleColor font-semibold mt-4'}>Resend Code</div>

            </div>

        </div>

    )
}
