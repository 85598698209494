import React from "react";
import SVGraphics from "../../assets/SVGraphics";
import "../../style/input/searchInput.css";

export function SearchInput(props) {

    return (
            <div className={`relative w-1/2 m-auto h-full`} style={props.containerStyle}>
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SVGraphics className="text-gray-600 h-4 w-4 fill-current" svgname={'glass'} />
                </div>
                <input type="search" id="default-search"
                       onChange={props.onChange}
                       className={`block w-full p-4 pl-10 text-[0.875rem] text-gray-900 border border-gray-300 rounded-3xl bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-0 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${props.inputClass}`}
                       placeholder="Search" required/>

            </div>
    )
}
