import React, {useState, useCallback, useEffect} from "react";
import {Table} from "../../table/table";
import {TextSanolla} from "../../input/TextSanolla";
import {SearchInput} from "../../input/SearchInput";
import {ButtonSanolla} from "../../input/ButtonSanolla";
import {ActionsMorePopover} from "../../table/tableMore/ActionsMorePopover";
import SVGraphics from "../../../assets/SVGraphics";
import OrganizationAdminApi from "../../../services/ApiServices/OrganizationAdminApi";
import AddClinicianModal from "../../modals/AddClinicianModal";
import {makeWarning} from "../../modals/WarningModal";


export function Clinicians() {
    const [showModal, setShowModal] = useState(false);
    const [clinicians, setClinicians] = useState([]);
    const [clinician, setClinician] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [closeModal, setCloseModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetchData().then(p => {
            if (p && p.data)
                setClinicians(setClinicianData(p.data))
                setFilteredData(setClinicianData(p.data))
            setCloseModal(false)
        })
        setIsLoading(false)
    }, [])
    const fetchData = async () => {
        return await OrganizationAdminApi.getAllClinicians()
    }
    const setClinicianData = (data) => {
        let _clinicians = []
        data.forEach(d=>{
            _clinicians.push({
                FirstName:d['PII']['FirstName'],
                LastName:d['PII']['LastName'],
                Role:d['PII']['Role'],
                PhoneNumber:d['PII']['PhoneNumber'],
                Email:d['PII']['Email'],
                UserStorageId:d['PII']['UserStorageId']
            })
        })
        return _clinicians
    }
    const handleShowModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        setIsEdit(false);
        setClinician({})
        setCloseModal(false)
    }, []);

    const handleCreateClinician = () => {
        fetchData().then(p => {
            if (p && p.data)
                setClinicians(setClinicianData(p.data))
                setFilteredData(setClinicianData(p.data))
                handleCloseModal()
            setCloseModal(false)
        })
    }
    const openEditModal = (user) => {
        setCloseModal(true)
        setClinician(user)
        setIsEdit(true)
        setShowModal(true)
    }

    const removeClinician = async (id) =>{
        setCloseModal(true)
        let remove
        let message = await makeWarning({proceedLabel: 'Delete',cancel:'Close', title:"Remove this clinician?",message:"This Clinician will be hidden immediately, you can’t undo this action."});
        if(message){
            remove = await OrganizationAdminApi.deleteClinician(id.UserStorageId)
            if(remove){
                fetchData().then(p => {
                    if (p && p.data)
                        setClinicians(setClinicianData(p.data))
                    setFilteredData(setClinicianData(p.data))
                })
            }
        }
        setCloseModal(true)
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'FirstName', // accessor is the "key" in the data
            sortType: 'string'
        },
        {
            Header: 'Last Name',
            accessor: 'LastName',
            sortType: 'string'
        },
        {
            Header: 'Role',
            accessor: 'Role',
        },
        {
            Header: 'Phone',
            accessor: 'PhoneNumber',
        },
        {
            Header: 'Email',
            accessor: 'Email',
        },
        {
            accessor: "[editButton]",
            maxWidth: "30px",
            width: "30px",
            minWidth: "30px",
            disableSortBy: true,
            Cell: ({cell}) => {
                return (
                    <div className={"flex justify-end"}>
                        <ActionsMorePopover
                            closeModal={closeModal}
                            // modalDisplay={modalDisplay}
                            cell={<div className="w-full h-full bg-white rounded-xl flex flex-col justify-evenly">
                                <div className={'flex justify-between h-1/2 border-b border-[#EBF2FC] cursor-pointer'}
                                     onClick={() => openEditModal(cell.row.original)}>
                                    <div
                                        className={"font-Poppins text-titleColor font-medium text-base flex flex-col justify-center ml-4"}>Edit
                                    </div>
                                    <SVGraphics className={"w-7 self-center mr-4 cursor-pointer"} svgname={`edit`}/>
                                </div>
                                <div className={'flex justify-between h-1/2 cursor-pointer'}
                                     onClick={()=> removeClinician(cell.row.original)}>
                                    <div
                                        className={"font-Poppins text-titleColor text-base font-medium flex flex-col justify-center ml-4"}>Delete
                                    </div>
                                    <SVGraphics className={"w-8 self-center mr-4 cursor-pointer"} svgname={`trash`}/>
                                </div>
                            </div>}
                        />
                    </div>
                )
            }
        },
    ]
    // const modalDisplay = (data) => {
    //     if(data){
    //         console.log("#########################",data)
    //         setCloseModal(true)
    //     }
    // }
    // const handlClickOutSide = () => {
    //     console.log("handlClickOutSide",closeModal)
    //     setCloseModal(false)
    // }
    // const onRowClick = (id) => {
    //     id = id.original.OrganizationID
    //     navigate('/admin/Organization', {state: id});
    // }

    const handleSearch = (event) => {
        let filterValue = event.target.value
        let filteredData = []
        for (let i = 0; i < clinicians.length; i++) {
            filteredData = clinicians.filter(user =>
                String(user['FirstName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['LastName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['PhoneNumber']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Email']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    return (
        <div className="overflow-x-auto overflow-y-auto  relative sm:rounded-lg w-tableWidth m-auto mt-10 "
            // onClick={()=>handlClickOutSide()}
        >
            <SearchInput
                onChange={(e) => handleSearch(e)}
            />
            <span className={'flex w-full justify-between items-center mt-5'}>
                <TextSanolla text={"Clinicians List"} className={"font-medium text-titleColor text-xl  "}/>
                 <ButtonSanolla onClick={handleShowModal}
                                containerClassName={'bg-sanollaButtonColor w-40 h-8 text-white rounded-lg text-[0.875rem] border border-sanollaButtonColor'}
                                label={'+ Add Clinician'}/>
            </span>
            {showModal &&
            <AddClinicianModal onCancel={handleCloseModal}
                               onSubmit={handleCreateClinician}
                               isEdit={isEdit}
                               entry={clinician}
            />}
            <div className={"w-full h-[720px] overflow-auto"}>
                <Table data={filteredData?filteredData:[]} columns={columns} isLoading={isLoading}
                       // onClick={(id) => onRowClick(id)}
                />
            </div>
        </div>
    )
}
