import React from "react";
import {InputSanolla} from "../input/InputSanolla";
import {PhoneInputSanolla} from "../input/PhoneInputSanolla";
import {SelectSanolla} from "../input/SelectSanolla";
import {EmailInputSanolla} from "../input/EmailInputSanolla";
import {InputNumberSanolla} from "../input/InputNumberSanolla";
import {Error} from "./Error";
import {PasswordInputSanolla} from "../input/PasswordInputSanolla";
import {InputImageSanolla} from "../input/InputImageSanolla";
import {TextAreaSanolla} from "../input/TextAreaSanolla";
import {InputFileSanolla} from "../input/InputFileSanolla";

/**
 * sanolla form receive array of form objects, each object contain: name(input name), type(input type), label(the field label), required(if the field is required), placeholder(input placeholder), error(field error)
 * also receive if need to show error, classname, onClick functions in props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export function SanollaForm(props) {
    let form = props.data
        let formData =[]
        let data = props.formData
        form.forEach(f=>{
            let label = f.required?f.label +' *':f.label
            let placeHolder = f.required?f.placeHolder +' *':f.placeHolder
            let isShown=false
            if(data[f.name] && data[f.name]!== ""){
                isShown=true
            }
            let labelClassName=`${isShown?'inline':'hidden'} text-{#657690} relative text-xs pl-1.5 pt-1 bottom-14 `
            if(f.type==='text') {
            formData.push(<div className={"mb-[28px]"} key={f.name}><InputSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    placeholder={placeHolder}
                    labelClassName={labelClassName}
                    name={f.name}
                    label={label}
                    maxLength={"40"}
                    value={data[f.name]&& data[f.name]!==""?data[f.name]:''}
                />
                {f.error?.length>0 && props.isShown[f.name] &&
                f.error.map(err=>{
                    return <Error
                        message={err}
                        isShown={props.isError && props.isShown[f.name]}/>
                })
                }
            </div>)
            }else if(f.type==='textArea') {
            formData.push(<div className={"mb-[28px]"} key={f.name}><TextAreaSanolla
                    containerClassName={props.containerClassName}
                    textAreaInputClassName={props.textAreaInputClassName}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    placeholder={placeHolder}
                    labelClassName={labelClassName+props.labelClassTextArea}
                    name={f.name}
                    label={label}
                    maxLength={"500"}
                    textAreaStyle={props.textAreaStyle}
                    value={data[f.name]&& data[f.name]!==""?data[f.name]:''}
                />
                {f.error?.length>0 && props.isShown[f.name] &&
                f.error.map(err=>{
                    return <Error
                        message={err}
                        isShown={props.isError && props.isShown[f.name]}/>
                })
                }
            </div>)
            }else if(f.type==='phone') {
                formData.push(<div className={"mb-[28px]"} key={f.name}><PhoneInputSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    placeholder={placeHolder}
                    maxLength={"15"}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name]?data[f.name]:''}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
            }else if(f.type==='select') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <SelectSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name]?data[f.name]:''}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
        }else if(f.type==='email') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <EmailInputSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    disabled={props.disabled}
                    placeholder={label}
                    onChange={props.onChange}
                    data={f.data}
                    maxLength={"40"}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name]?data[f.name]:''}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }

                </div>)
        }else if(f.type==='image') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <InputImageSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    disabled={props.disabled}
                    placeholder={placeHolder}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={"text-{#657690} relative text-xs pl-1.5 pt-1 bottom-14"}
                    label={data[f.name]}
                    fileName={props.fileName}
                    previewImage={props.previewImage}
                    value={data[f.name]}
                    deletePhoto={props.deletePhoto}
                    removeStyle={props.removeStyle}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
        }else if(f.type==='file') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <InputFileSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    disabled={props.disabled}
                    placeholder={placeHolder}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={"text-{#657690} relative text-xs pl-1.5 pt-1 bottom-14"}
                    label={data[f.name]}
                    fileName={props.fileName}
                    previewImage={props.previewImage}
                    value={data[f.name]}
                    accept={f.accept}
                    deletePhoto={props.deletePhoto}
                    removeStyle={props.removeStyle}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
            }
            else if(f.type==='number') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <InputNumberSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={labelClassName}
                    label={label}
                    maxLength={f.maxLength}
                    max={f.max}
                    min={f.min}
                    value={data[f.name]?data[f.name]:''}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
        }else if(f.type==='password') {
                formData.push(<div className={"mb-[28px]"} key={f.name}> <PasswordInputSanolla
                    containerClassName={props.containerClassName}
                    inputClassName={props.inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={labelClassName}
                    label={label}
                    maxLength={"20"}
                    showPassword={props.type[f.name]}
                    type={props.type[f.name] ? props.type[f.name] === true?'text':'password':'password'}
                    value={data[f.name]?data[f.name]:''}
                    showOnClick={props.showOnClick}
                />
                    {f.error?.length>0 && props.isShown[f.name] &&
                    f.error.map(err=>{
                        return <Error
                            message={err}
                            isShown={props.isError && props.isShown[f.name]}/>
                    })
                    }
                </div>)
        }
    });


    return (
           <>
               {formData}
           </>

    )
}
