import SVGraphics from "../../../../assets/SVGraphics";
import React from "react";
import heart from "../../../../assets/heart.png";
import lungs from "../../../../assets/lungs.png";
import download from "../../../../assets/download.png";

export function GetExam(props){
    const getBodyPart = (name) => {
        switch (name){
            case "Aortic":
            case "Pulmonic":
            case "Erb's":
            case "Tricuspid":
            case "Mitral":
                return (<img className={"w-4 self-center mr-1"}
                             alt={"H"}
                             src={heart}/>
                )
            default:
                return( <img className={"w-4 self-center mr-1"}
                            alt={ "L"}
                            src={ lungs}/>
                )
        }
    }
    return(

        <div key={props.result.pointID}>
            <div className={"flex mb-7"}>
                {props.result.status === "successful" ?
                    <>
                    <SVGraphics className="w-8 h-8" svgname={props.svgName} onClick={(r)=>props.onClick(r)}/>
                        {props.result.uploadedFiles.find(x => x.type === 'audio') &&
                        <audio
                            preload="metadata"
                            onEnded={(r)=>props.setPaused(r)}
                            id={String(props.result.uploadedFiles.find(x => x.type === 'audio').index)}
                        >

                        </audio>
                        }
                    </>

                    :
                    <SVGraphics className="w-8 h-8" svgname={'fail'}/>
                }
                <div>
                    <div
                        className={"ml-2.5 text-base font-Poppins text-titleColor font-medium flex"}>
                        {getBodyPart(props.name)}
                        {props.name}
                    </div>
                    <div
                        className={"ml-2.5 font-Poppins text-[#657690] text-xs"}>
                        {props.result.status === "successful" ? "Successful Scan" : props.result.status}
                        {props.result.note &&
                        <span> &#183; {props.result.note}</span>}
                    </div>
                </div>
                <a
                    href={props.result?.uploadedFiles.find(x => x.type === 'audio')?.link}
                    target="_blank"
                    rel="noopener"
                    download={`${props.result?.uploadedFiles.find(x => x.type === 'audio')?.fileName}.wav`}
                >
                <img className="w-5 h-5 ml-2.5 self-center justify-self-center" alt={"download"} src={download} />
                </a>
            </div>
            <div className={"image-scroll flex ml-7 h-32 border border-[#EBF2FC] w-max max-w-[96%] rounded-xl overflow-x-auto overflow-y-hidden"}>
                {props.result.uploadedFiles.map(photo => {
                    if (photo.type === "photo")
                        return <img key={photo.index} className={"h-32"} alt={"scan"} src={photo.link} />
                    else
                        return ""
                })
                }
            </div>
        </div>

    )
}

