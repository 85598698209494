import {useDropzone} from "react-dropzone";
import "../../style/input/dropZone.css"

function Dropzone({ onDrop, accept, open, containerClass }) {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
        });

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div>
            <div {...getRootProps({ className: containerClass})}>
                <input className="input-zone" {...getInputProps()} />
                <div className="text-center">
                    {isDragActive ? (
                        <p className="dropzone-content">
                            Release to drop the files here
                        </p>
                    ) : (
                        <p className="mt-4">
                            Drag’ n’ drop files <br/>

                        </p>

                    )}
                    <>
                        <p className={"mt-4"}>----- or -----</p>
                        <button type="button" onClick={open} className="btn">
                            &#128194;
                        </button>
                        <p className={"mt-1"}>Browse</p>
                    </>

                </div>
            </div>
            <aside>
                <ul>{files}</ul>
            </aside>
        </div>
    );
}

export default Dropzone;